import { PALOPTL } from '../../../utils/api';
import { project } from '../../../gql';
import { PROJECT_CATEGORY } from '../../../routes';
import projectModule from './modules/project';
import _ from 'lodash';

export default {
    strict: true,
    namespaced: true,
    modules: {
        project: projectModule,
    },
    state: {
        loading: false,
        loadingSubSubCategory: false,
        hasMore: false,
        hasMoreSubSubCategory: false,
        categories: [],
        subSubCategories: [],
    },
    mutations: {
        categories(state, payload) {
            state.categories = payload || [];
        },
        appendCategories(state, payload) {
            state.categories = _.concat(state.categories, payload || []);
        },
        subSubCategories(state, payload) {
            state.subSubCategories = payload || [];
        },
        appendSubSubCategories(state, payload) {
            state.subSubCategories = _.concat(state.subSubCategories, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        loadingSubSubCategory(state, payload) {
            state.loadingSubSubCategory = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
        hasMoreSubSubCategory(state, payload) {
            state.hasMoreSubSubCategory = payload;
        }
    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
            };
            const res = await PALOPTL().post('/', {
                query: project.category.all,
                variables,
            });
            const queryRes = res.data.data.projectCategories;
            const allCategory = queryRes.edges.map(toPost);
            
            commit('loading', false);
            commit('hasMore', queryRes.pageInfo.hasNextPage);

            return allCategory;
        },
        async list ({ commit, dispatch }, payload)  {
            const categories = await dispatch('load', payload);
            commit('categories', categories);
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            const categories = await dispatch('load', { ...payload, cursor: getters.lastCursor});
            commit('appendCategories', categories);
        },


        async loadSubSubCategory({ commit }, payload) {
            commit('loadingSubSubCategory', true);
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
            };
            const res = await PALOPTL().post('/', {
                query: project.subSubCategory.all,
                variables,
            });
            const queryRes = res.data.data.projectSubSubCategories;
            const allCategory = queryRes.edges
                .filter((e) => e.node.status )
                .map(toSubSubCategoryOption);
            
            commit('loadingSubSubCategory', false);
            commit('hasMoreSubSubCategory', queryRes.pageInfo.hasNextPage);

            return allCategory;
        },
        async listSubSubCategory ({ commit, dispatch }, payload)  {
            const categories = await dispatch('loadSubSubCategory', payload);
            commit('subSubCategories', categories);
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.categories)) {
                return null;
            }
            const len = state.categories.length;
            return state.categories[len -1].$cursor;
        }
    }
}


function toPost(edge) {
    const node = edge.node;
    return {
        id: node.id,
        title: node.name,
        src: '/assets/img/plholder.jpg',
        $cursor: edge.cursor,
        $route: { path: `${PROJECT_CATEGORY}/${node.id}`,}
    };
} 


function toSubSubCategoryOption(edge) {
    const node = edge.node;
    return {
        id: node.id,
        title: node.name,
        description: `${node.subcategory.category.name}/${node.subcategory.name}`
    };
}