<template>
  <div style="padding: 0 16px 0 16px">
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import breadcrumbs from '../breadcrumbs'
  export default {
    props: ['page'],
    data: () => ({}),
    computed: {
        items() {
            return breadcrumbs[this.page];
        }
    }
  }
</script>