import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#fc0',
                secondary: '#171717',
                accent: '#737373',
                error: '#FF5252',
            },
        },
    },
});