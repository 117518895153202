import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faComment, faHeart, faRedoAlt, faCalendar,
    faMapMarkerAlt, faUserCircle, faGlobe, faAward,
    faCircle, faSignOutAlt, faCogs, faPlay, faFilm, faCog,
    faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faComment, faHeart, faRedoAlt, faCalendar,
    faMapMarkerAlt, faUserCircle, faGlobe, faAward,
    faCircle, faSignOutAlt, faCogs, faPlay, faFilm, faCog,
    faArrowLeft
);

Vue.component('font-awesome-icon', FontAwesomeIcon);