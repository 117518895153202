import { PALOPTL, FILE_URL } from '../../../../utils/api';
import { festival } from '../../../../gql';
import { FESTIVAL } from '../../../../routes';
import moment from 'moment';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        loading: false,
        hasMore: false,
        category: null,
        festivals: [],
        festival: null,
        isMyFestival: false,
        mySubscriptions: [],
        subscriptionsCount: [],
        favorites: [],
        error: null
    },
    mutations: {
        category(state, payload) {
            state.category = payload;
        },
        festivals(state, payload) {
            state.festivals = payload || [];
        },
        festival(state, payload) {
            state.festival = payload;
        },
        appendFestivals(state, payload) {
            state.festivals = _.concat(state.festivals, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        error(state, payload) {
            state.error = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
        isMyFestival(state, payload) {
            state.isMyFestival = payload;
        },
        mySubscriptions(state, payload) {
            state.mySubscriptions = payload;
        },
        subscriptionsCount(state, payload) {
            state.subscriptionsCount = payload;
        },
        favorites(state, payload) {
            state.favorites = payload;
        }
    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                id: payload.categoryId,
                limit: payload.limit,
                cursor: payload.cursor,
            };

            if (payload.name) variables.name = payload.name;
            if (payload.startingDate) variables.startingDate = payload.startingDate;
            
            const res = await PALOPTL().post('/', {
                query: festival.festival.all,
                variables,
            });
            const queryRes = res.data.data.category;
            const obj = {
                category: toPost(queryRes),
                items: queryRes.festivals.edges.map(toFestival),
            }
            
            commit('loading', false);
            commit('hasMore', queryRes.festivals.pageInfo.hasNextPage);

            return obj;
        },

        async listFilter ({ commit }, payload)  {
            commit('festivals', []);
            commit('category', null);

            commit('loading', true);

            try {
                const variables = {
                    category: payload.category,
                    limit: payload.limit,
                    profile: payload.profile
                };
    
                if (payload.name) variables.name = payload.name;
                if (payload.date) variables.startingDate = payload.date;
                if (payload.local) variables.local = payload.local;
                
                const res = await PALOPTL().post('/', {
                    query: festival.festival.filter,
                    variables,
                });

                const queryRes = res.data.data.festivals;
                if (!_.isNil(queryRes)) {
                    const obj = {
                        category: toPost(queryRes[0].category),
                        items: queryRes.map(node => {
                            let allowedProjects = []
                            if (node.allowedProjects && node.allowedProjects.edges) {
                                allowedProjects = node.allowedProjects.edges.map(node => {
                                    return {
                                        name: node.node.name + ' : ' + `${node.node.subcategory.category.name}/${node.node.subcategory.name}`,
                                        id: node.node.id
                                    }
                                })
                            }
                            return {
                                id: node.id,
                                title: node.name,
                                cartaz: FILE_URL + '/' + node.cartaz,
                                startingDate: node.startingDate,
                                description: node.description.slice(0, 200),
                                subscriptionDeadline: moment(node.registerDateLimit).format('DD • MM • YYYY'),
                                registerDateExpired: node.subscriptionsOpen,
                                src: FILE_URL + '/' + node.cartaz,
                                allowedProjects: allowedProjects,
                                addedToUserFavorite: node.addedToUserFavorite,
                                $route: {path: `${FESTIVAL}/${node.id}`}
                            }
                        }),
                    }

                    commit('loading', false);
                    commit('category', obj.category);
                    commit('festivals', obj.items);
                } else {
                    commit('loading', false);
                    commit('error', {
                        success: false,
                        error: 'Lamentamos mas não foi encontrado nenhum resultado para a sua pesquisa.'
                    });
                }

            } catch (error) {
                commit('loading', false);
                commit('error', {
                    success: false,
                    error: 'Erro no processamento do pedido. Por favor tente mais tarde.'
                });
            }
        },

        async list ({ commit, dispatch }, payload)  {
            commit('festivals', []);
            commit('category', null);
            const festivals = await dispatch('load', payload);
            commit('category', festivals.category);
            commit('festivals', festivals.items);
        },

        async loadMore({ commit, dispatch, getters }, payload) {
            const festivals = await dispatch('load', { ...payload, cursor: getters.lastCursor});
            commit('appendFestivals', festivals.items);
        },
        async detail({ commit }, payload) {
            commit('loading', true);
            commit('festival', {});
            const variables = {
                id: payload.id,
                profile: payload.profile
            };
            const res = await PALOPTL().post('/', {
                query: festival.festival.one,
                variables,
            });
            const queryRes = res.data.data.festival;
            commit('loading', false);
            commit('festival', toDetailedFestival(queryRes));
        },

        async isMyFestival({commit}, payload) {
            const res = await PALOPTL().post('/', {
                query: festival.festival.isMyFestival,
                variables: { ...payload }
            });
            const queryRes = res.data.data.isMyFestival;
            commit('isMyFestival', queryRes.isMyFestival);
        },

        async mySubscriptions({ commit }, payload) {
            const res = await PALOPTL().post('/', {
                query: festival.festival.subscriptions,
                variables: { ...payload }
            });
            const queryRes = res.data.data
            if (queryRes && queryRes.subscriptions) {
                const subscriptions = queryRes.subscriptions.edges.map(toSubscriptions);
                commit('mySubscriptions', subscriptions);
            }
        },

        async subscriptionsByFestival({ commit }, payload) {
            const res = await PALOPTL().post('/', {
                query: festival.festival.subscriptionsByFestival,
                variables: { ...payload }
            });
            const queryRes = res.data.data
            if (queryRes && queryRes.subscriptions) {
                const subscriptions = queryRes.subscriptions.edges.map(toSubscriptions);
                commit('mySubscriptions', subscriptions);
            }
        },

        async countSubscriptions({ commit }, payload) {
            const res = await PALOPTL().post('/', {
                query: festival.festival.countSubscriptions,
                variables: { ...payload }
            });
            const queryRes = res.data.data.subscriptionsCount;

            commit('subscriptionsCount', { ...queryRes });
        },

        async favorites({ commit }, payload) {
            const res = await PALOPTL().post('/', {
                query: festival.festival.favorites,
                variables: { ...payload }
            });
            const queryRes = res.data.data.favorites;
            commit('favorites', queryRes.edges.map(toFavorites));
        },

        async manageFavorites({ commit }, payload) {
            try {
                const res = await PALOPTL().post('/', {
                    query: festival.festival.manageFavorites,
                    variables: { input: { ...payload } }
                });
                const queryRes = res.data.data.manageFavorites;
                commit('error', queryRes.status);
            } catch (error) {
                commit('error', {
                    success: false,
                    error: 'Erro no processamento do pedido. Por favor tente mais tarde.'
                });
            }
        },

        async lastSubscriptions({ commit }, payload) {
            const res = await PALOPTL().post('/', {
                query: festival.festival.lastSubscriptions,
                variables: { ...payload }
            });
            const queryRes = res.data.data.lastSubscriptions;
            const subs = queryRes.edges.map(node => {
                return {
                    festival: node.node.festival.name,
                    project: node.node.project.name,
                    requestDate: moment(node.node.requestDate).format('DD • MM • YYYY, hh:mm'),
                }
            });
            commit('mySubscriptions', subs);
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.festivals)) {
                return null;
            }
            const len = state.festivals.length;
            return state.festivals[len -1].$cursor;
        }
    }
}


function toPost(node) {
    return {
        id: node.id,
        title: node.name,
        src: '/assets/img/plholder.jpg',
    };
} 

function toFestival(edge) {
    const node = edge.node;
    return {
        id: node.id,
        title: node.name,
        cartaz: FILE_URL + '/' + node.cartaz,
        startingDate: node.startingDate,
        description: node.description.slice(0, 200),
        subscriptionDeadline: moment(node.registerDateLimit).format('DD • MM • YYYY'),
        $cursor: edge.cursor,
        src: FILE_URL + '/' + node.cartaz,
        $route: {path: `${FESTIVAL}/${node.id}`}
    };
}


function toDetailedFestival(node) {
    if (!node) {
        return null;
    }
    let allowedProjects = []
    if (node.allowedProjects.edges)
        allowedProjects = node.allowedProjects.edges.map(node => {
            return {
                name: node.node.name + ' : ' + `${node.node.subcategory.category.name}/${node.node.subcategory.name}`,
                id: node.node.id
            }
        })

    return {
        id: node.id,
        title: node.name,
        body: node.description,
        subscriptionDeadline: moment(node.registerDateLimit).format('DD • MM • YYYY'),
        registerDateExpired: node.subscriptionsOpen,
        maxSubsReached: node.maxSubsReached,
        maxSubsAllowed: node.maxSubsAllowed,
        addedToUserFavorite: node.addedToUserFavorite,
        date: moment(node.dataPublished).format('DD / MM / YYYY'),
        src:  FILE_URL + '/' + node.avatar,
        cartaz: FILE_URL + '/' + node.cartaz,
        creator: node.creator,
        allowedProjects: allowedProjects,
        awards: [
            {
                src: '/assets/img/plaward.png'
            },
            {
                src: '/assets/img/plaward.png'
            },
            {
                src: '/assets/img/plaward.png'
            }
        ]
    };
}

function toSubscriptions(node) {
    return {
        id: node.id,
        requestDate: moment(node.node.requestDate).format('DD • MM • YYYY, hh:mm'),
        validationDate: moment(node.node.validationDate).format('DD • MM • YYYY hh:mm'),
        status: node.node.status,
        comment: node.node.comment,
        project: {
            id: node.node.project.id,
            name: node.node.project.name,
            author: node.node.project.author,
            credits: node.node.project.credits ? node.node.project.credits.edges.map(node => node.node) : [],
            awards: node.node.project.awards ? node.node.project.awards.edges.map(node => node.node) : [],
            coverPic: node.node.project.media ? node.node.project.media.edges.map(node => FILE_URL + '/' + node.node.coverPic)[0] : null,
            externalLink: node.node.project.externalLink,
            createdAt: moment(node.node.project.createdAt).format('DD • MM • YYYY hh:mm') || null,
            sinopse: node.node.project.sinopse || null,
            bio: node.node.project.bio,
            category: node.node.project.category.name + ' : ' 
                + `${node.node.project.category.subcategory.category.name}/${node.node.project.category.subcategory.name}`,
        },
        festival: {
            location: node.node.festival.location,
            name: node.node.festival.name,
            id: node.node.festival.id,
            src: FILE_URL + '/' + node.node.festival.avatar,
            category: node.node.festival.category.name,
            description: node.node.festival.description,
            creator: node.node.festival.creator,
            startingDate: node.node.festival.startingDate,
            trackn: node.node.festival.trackn
        }
    }
}

function toFavorites(node) {
    return node.node.festival;
}