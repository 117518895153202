export const projects = `
query projects($festival: ID!, $status: String!) {
  subscriptions (festival: $festival, status: $status){
      pageInfo { hasNextPage }
      edges {
          cursor
          node {
              id status
              auxiliarreview: judgereview {id reviews}
              project {id title: name externalLink cover}
              festival {
                criterias: festivalcriteriasSet {
                  edges {node {id criteria}}
                }
              }
          }
      } 
  }
}`;


export const submitReview = `
mutation ($input:  SubmitAuxiliarReviewInput!) {
    submitAuxiliarReview(input:  $input) {
      review { id }
      status {
        success message
      }
    }
}`;