/* eslint-disable */
import axios from 'axios'

const DOMAIN = 'https://api.redecinemapaloptl.org';
const LOCALHOST = `${DOMAIN}/paloptl/api`;
const FILE_DOMAIN = 'https://api.redecinemapaloptl.org';
const FILE_URL = `${FILE_DOMAIN}/media`;

const SAVE_PROJECT_FILE = '/project/file/';

const STORAGE_UPLOAD = '/storage/upload/';

const GQL_API = '/';

const STORAGE_TYPE = Object.freeze({
    PROFILE: 'PROFILE',
    PORTFOLIO_COVER: 'PORTFOLIO_COVER',
})

const HEADERS = {
    "Content-Type": "application/json",
};

const PALOPTL = () => {
    const API = axios.create({
        baseURL: LOCALHOST,
       // timeout: 4000,
        headers: HEADERS
    })
    return API
};

const FILE_UPLOADER = (headers) => {
    const API = axios.create({
        baseURL: FILE_DOMAIN,
        timeout: 4000,
        headers,
    })
    return API
};


const CancelRequest = () => {
  const cancelToken = axios.CancelToken
  return cancelToken
};

export {
    PALOPTL, CancelRequest, FILE_UPLOADER, 
    FILE_URL, SAVE_PROJECT_FILE, STORAGE_UPLOAD, 
    STORAGE_TYPE, GQL_API,
    DOMAIN
};
