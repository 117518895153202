export const projects = `
query projects($festival: ID!, $status: String!) {
    subscriptions (festival: $festival, status: $status){
        pageInfo { hasNextPage }
        edges {
            cursor
            node {
                status id
                project {
                    id title: name sinopse externalLink cover
                    credits: projectcreditSet { edges { node { name } } }  
                }
              	review: principalreview {
                    id review observation
                }
            }
        }
    }
}`;

export const submitReview = `
mutation review($input: SubmitPrincipalReviewInput!) {
    submitPrincipalReview(input: $input) {
        review {id review observation subscription{status}}
        status {error success}
    }
}
`;