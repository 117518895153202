export const jurysFestivals = `
query($user: ID!) {
    myfestival: judges(user: $user) {
      edges {
        cursor
        node {
          category
          festival {
            id name avatar cartaz
          }
        }
      }
    }
}`;