var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',{class:_vm.item.class,staticStyle:{"display":"inline"}},[(!_vm.item.children || _vm.item.children.length === 0)?_c('router-link',{attrs:{"to":_vm.item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var isActive = ref.isActive;
return [_c('v-btn',{staticClass:"menu",class:isExactActive || (isActive && href !== '/') ? 'active' : '',attrs:{"to":href,"small":"","text":""}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.item.title))])])]}}],null,false,856960792)}):_c('v-menu',{attrs:{"open-on-hover":true,"close-on-content-click":true,"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":_vm.item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var isActive = ref.isActive;
return [_c('v-btn',_vm._g({staticClass:"menu hidden-md-and-down",class:isExactActive || (isActive && href !== '/') ? 'active' : '',attrs:{"to":href,"text":"","small":"","router-link":""}},on),[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.item.title))])])]}}],null,true)})]}}],null,false,1672358384)},[_c('v-list',{staticClass:"menu-dropdown",attrs:{"dense":""}},[_vm._l((_vm.item.children),function(child,index){return [_c('v-list-item',{key:index+'-li',class:'menu-item'},[_c('v-btn',{attrs:{"text":"","small":"","color":"black","to":child.route}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(child.title))])])],1),((index+1) < _vm.item.children.length)?_c('div',{key:index+'-d',staticClass:"menu-divider ml-2",staticStyle:{"width":"80%"}}):_vm._e()]})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }