export const submitReview = `
mutation ($input:  SubmitJury2ReviewInput!) {
    submitJury2Review(input:  $input) {
      review { id status }
      status { success message }
    }
}`;

export const reviews = `query projects($festival: ID!, $status: String!, $subscription_status: ID!) {
  reviews: judgeReviews (subscription_Festival: $festival, status: $status, subscription_Status: $subscription_status){
      pageInfo { hasNextPage }
      edges {
          cursor
          node {
              id status reviews
            	subscription {
                id hasAuxReviewUnanimity hasAuxReviewCompleted
                project {id title: name externalLink cover}
              }
          }
      } 
  }
}`;