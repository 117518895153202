import Vue from 'vue';
import App from './App.vue';

import VueSession from 'vue-session';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VeeValidate from 'vee-validate';

import vuetify from './plugins/vuetify';
import './plugins/font-awesome';
import './plugins/router';
import './plugins/store';
import './plugins/auth';

import 'dotenv/config';

import './main.css';
import router from './router';
import store from './store';
import Breadcrumbs from './components/Breadcrumbs';

Vue.config.productionTip = false;

Vue.use(VueSession);
Vue.use(VeeValidate);
Vue.use(CKEditor);
Vue.component('p-breadcrumbs', Breadcrumbs);
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
