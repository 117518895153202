import {PALOPTL, GQL_API, FILE_URL } from '../../../utils/api';
import { storage } from '../../../utils';
import { profileDashboard } from '../../../routes';
import { dashboard } from '../../../gql';
import _ from 'lodash';

const myFestival = dashboard.festival.my;

// Festival Profile festival
export default {
    strict: true,
    namespaced: true,
    state: {
        festivals: [],
        loading: false,
        festival:null,
        processingFestival: false,
        hasMore: false,
        judges: []
    },
    mutations: {
        festivals(state, payload) {
            state.festivals = payload || [];
        },
        festival(state, payload) {
            state.festival = payload;
        },
        appendFestivals(state, payload) {
            state.festivals = _.concat(state.festivals, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        processingFestival(state, payload) {
            state.processingFestival = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
        judges(state, payload) {
            state.judges = payload;
        }
    },

    actions: {

        async detail({ commit }, payload) {
            commit('loading', true);
            commit('festival', null);

            const variables = {
                id: payload.id,
                withoutSubscriptions: payload.withoutSubscriptions,
            };
            const res = await PALOPTL().post(GQL_API, {
                query: myFestival.detail,
                variables,
            });
            const queryRes = res.data.data.festival;
            
            const one = toFestival({node: queryRes}, payload.noFile);
            
            commit('loading', false);
            commit('festival', one);
        },

        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
                profile: payload.profile
            };

            const res = await PALOPTL().post(GQL_API, {
                query: myFestival.list,
                variables,
            });

            const queryRes = res.data.data.festivals;
            const all = queryRes.edges.map(toFestival);
            commit('loading', false);
            commit('hasMore', queryRes.pageInfo.hasNextPage);

            return all;
        },
        async list ({ commit, dispatch }, payload)  {
            try {
                const festivals = await dispatch('load', payload);
                commit('festivals', festivals);
            } catch (err) {
                commit('loading', false);
            }
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            try {
                const festivals = await dispatch('load', { ...payload, cursor: getters.lastCursor});
                commit('appendFestivals', festivals);
            } catch(err) {
                commit('loading', false);
            }
        },

        async createOrUpdate({ commit }, payload) {
            const data = payload.data;
            const update = !!data.id; 
            const token = payload.token
            commit('processingFestival', true);

            try {
                const input = {
                    name: (data.name || '').trim(),
                    category: data.category ? data.category.id : null,
                    description: data.description,
                    status: data.status,
                    registerDateLimit: data.registerDateLimit,
                    startingDate: data.startingDate,
                    location: data.location,
                    judges: data.judges.map(judge => {
                        return {
                            user: judge.id,
                            category: judge.category
                        }
                    }),
                    criterias: data.criterias
                };

                let query;
                if (update) {
                    input['key'] = data.id;
                    query = myFestival.update;
                } else {
                    query = myFestival.create;
                    input.creator = token.uid.id;
                    input.allowedProjects = (data.allowedProjects || []).map((o) => o.id);
                }
                
                const res = await PALOPTL().post(GQL_API, {
                    query: query,
                    variables: { input: input }
                });

                const queryRes = update ? 
                        res.data.data.updateFestival : 
                        res.data.data.createFestival;

                if (!queryRes.festival) {
                    commit('processingFestival', false);
                    return { 
                        isUpdate: update, 
                        success: false, 
                        messages: [{
                            success: false,
                            message:  queryRes.status.error,
                        }]
                    };
                }

                
                const id = queryRes.festival.id;
                const messages = []
                let fileSuccess = true;
                try {
                    const fileMessage = {
                        success: false,
                        message: 'Problema ao caregar ficheiro.',
                    }

                    const success = await storage.upload(
                        data.srcFile, 
                        id, 
                        storage.TYPE.festival.festival.avatar,
                        { 
                            fileInputName: 'file', elementInputName: 'element', 
                            typeStorageInputName: 'type'
                        }
                    );
                    
                    if (data.cartaz)
                        await storage.upload(
                            data.cartaz, 
                            id, 
                            storage.TYPE.festival.festival.cartaz,
                            { 
                                fileInputName: 'file', elementInputName: 'element', 
                                typeStorageInputName: 'type'
                            }
                        );

                    if (success) {
                        fileMessage.success = true;
                        fileMessage.message = 'Ficheiro Carregado com sucesso.';
                        messages.push(fileMessage);
                    }
                    
                } catch(err) {
                    messages.push({
                        success: false,
                        message: 'Problema ao caregar ficheiro.',
                    });
                    fileSuccess = false;
                }

                commit('processingFestival', false);

                return {
                    isUpdate: update,
                    success: queryRes.status.success == 'true' && fileSuccess,
                    data: { ...data, id},
                    messages: [{
                        success: queryRes.status.success == 'true',
                        message: queryRes.status.error
                    }, ...messages],
                };
            } catch(err) {
                commit('processingFestival', false);
                return {
                    success: false,
                    isUpdate: update,
                    messages: [{
                        success: false,
                        message: 'Problema no processamento do pedido.',
                    }]
                };
            }
        },

        async delete({ commit, state }, payload) {
            commit('loading', true);
            const data = payload.data;
            //const token = payload.token
            commit('processingFestival', true);

            try {
                const input = {
                    //profile: token.id,
                    pk: data.id,
                };

                const query = myFestival.del;
                
                const res = await PALOPTL().post(GQL_API, {
                    query: query,
                    variables: { input }
                });

                const queryRes = res.data.data.deleteFestival;
                commit('processingFestival', false);

                if (queryRes.status.success == 'true') {
                    const festivals = state.festivals.filter(o => o.id != data.id);
                    commit('festivals', festivals);
                }
                commit('loading', false);
                return {
                    success: queryRes.status.success == 'true',
                    messages: [{
                        success: queryRes.status.success == 'true',
                        message: queryRes.status.error
                    },],
                };
            } catch(err) {
                commit('processingFestival', false);
                commit('loading', false);
                return {
                    success: false,
                    messages: [{
                        success: false,
                        message: 'Problema no processamento do pedido.',
                    }]
                };
            }
        },

        async getJudges({ commit }) {
            const res = await PALOPTL().post('/', {
                query: myFestival.judges,
                variables: { isActive: true }
            });
            const queryRes = res.data.data.judges;
            
            if (!_.isNil(queryRes)) {
                const judges = queryRes.edges.map(node => {
                    return {
                        name: node.node.firstName + node.node.lastName,
                        email: node.node.email,
                        id: node.node.user.id
                    }
                });
                commit('judges', judges);
            }
        }
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.festivals)) {
                return null;
            }
            const len = state.festivals.length;
            return state.festivals[len -1].$cursor;
        }
    }
}


function toFestival(edge, noFile) {
    const node = edge.node;
    if (!node) return null;

    return {
        ...node,
        criterias: node.criterias ? (node.criterias.edges || []).map(e => {
            return {
                name: e.node.criteria,
                status: e.node.status
            }
        }) : [],
        judges: node.judges ? (node.judges.edges || []).filter(e => e && e.node).map(e => {
            let str = e.node.category ? e.node.category.toLowerCase() : '';
            return {
                category: str.charAt(0).toUpperCase() + str.slice(1),
                email: e.node.user.email,
                name: e.node.user.firstName + ' ' + e.node.user.lastName,
                id: e.node.user.id
            }
        }) : [],
        cartaz: noFile ? null : node.cartaz ? `${FILE_URL}/${node.cartaz}` : null ,
        avatar: noFile ? null : node.avatar ? `${FILE_URL}/${node.avatar}` : null,
        allowedProjects: node.allowedProjects  ? 
            (node.allowedProjects.edges || []).map(e => e.node) : [],
        subscriptions: node.subscriptions  ? 
            (node.subscriptions.edges || []).map(e => e.node) : [],
        $cursor: edge.cursor,
        $route: { 
            detail: {path: `${profileDashboard.festival.myFestivals.detail}/${node.id}`,},
            edit: {path: `${profileDashboard.festival.myFestivals.edit}/${node.id}`,},
        }
    };
}
