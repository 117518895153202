<template>
    <v-footer
    :class="'card-secondary'" min-height="300"
    padless><!-- 225 -->
      <v-container :class="'card-secondary'">
        <v-row
          class="card-secondary"
          justify="center" align="center"
          no-gutters>
          
          

          <v-col cols="12" md="12" class="card-secondary">
            <v-container fluid>
              <v-row class="card-secondary " align="center" justify="center">
                <v-col
                  v-for="link in links"
                  :key="link.section" xs="12">
                  <v-btn
                    x-small
                    text
                    rounded
                    :href="link.href"
                    
                    class="my-2">
                    <span>{{ link.section }}</span>
                  </v-btn>
                  <ul>
                    <li style="margin-left: 0" v-for="item in link.items" :key="link.section+'-'+item.title">
                      <v-btn 
                          x-small 
                          text rounded
                          :target="item.target"
                          :href="item.href">
                              <span>{{ item.title }}</span>
                      </v-btn>
                    </li>
                  </ul>
                </v-col>
                
              </v-row>

            </v-container>
          </v-col>
        </v-row>
        <br/>
        <v-row
          class="card-secondary"
          
          no-gutters align="center" justify="center">
          <v-col cols="12" md="4">
            <v-container class="pb-0" fluid>
              <v-row class="ml-md-2 pl-md-2" no-gutters align="center" justify="center">
                <v-col cols="12">
                  <p 
                    class="mb-2 text-center">
                    <i class="text-uppercase white--text" style="font-size: 13px;">Financiado Por:</i>
                  </p>
                  <v-row  no-gutters justify="center" align="center">

                      <a href="https://europa.eu/european-union/" target="_blank">
                        <img
                            alt="União Europeia"
                            class="shrink"
                            src="/assets/img/LogoUE_Branco.png"/>
                      </a>
                      
                      <div style=" display: inline" class="ml-4 px-1">
                        <a href="https://palop-tl.eu" target="_blank">
                          <img
                            alt="PALOP TL"
                            class="shrink mr-2"
                            src="/assets/img/ANM-PALOP-TL-UE_Branco.png"/>
                        </a>
                      </div>
                  </v-row>
                  <p 
                  class=" mb-2 text-center" 
                  style="font-size: 10px;">&copy; REDE CINEMA PALOP-TL, {{ new Date().getFullYear() }}. All Rights Reserved.</p>
                </v-col>
                <v-divider class="d-none d-sm-none d-sm-flex" style="border-right-width: 2px; border-right-color: white"
                  vertical inset
                ></v-divider>
              </v-row>
            </v-container>
 
          </v-col>
          <v-col cols="12" md="6" order="2" order-md="3">
            <v-container fill-height fluid>
              <v-row align="center"
                  justify="center">
                  <v-col>

                    <p class="mt-2 ml-3 ml-md-4 pl-md-7 mr-4 mb-0 text-justify" style="font-size: 10px;">
                      Este sítio web foi criado e é mantido com o apoio financeiro da União Europeia. 
                      
                    </p>
                    <p class="ml-3 ml-md-4 pl-md-7 mr-4 text-justify"  style="font-size: 10px;">
                      O seu conteúdo é da exclusiva responsabilidade da Rede Cinema e Audiovisual PALOP-TL e 
                      não reflete necessariamente a posição da União Europeia.
                    </p>
                  </v-col>
              </v-row>
            </v-container>

            
          </v-col>

        </v-row>
      </v-container>
  </v-footer>
</template>

<script>
export default {
    props: ['links',],
}
</script>