<template>
    <v-list-item  
        v-if="!item.children || item.children.length === 0" 
        :to="item.route">
        <v-list-item-title class="text-uppercase">{{ item.title }}</v-list-item-title>
    </v-list-item>

    <v-list-group v-else>
        <template v-slot:activator>
            <v-list-item-title 
                class="text-uppercase"
                @click="toRoute(item.route)">{{ item.title }}</v-list-item-title>
        </template>

        <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.route" class="ml-4">
            <v-list-item-title class="text-uppercase">{{ child.title }}</v-list-item-title>
        </v-list-item>
    </v-list-group>
</template>

<script>
export default {
    props: ['item'],
    methods: {
        toRoute(route) {
            this.$router.push(route);
        }
    }
}
</script>