<template>
    <div>
        <PostContainer :headless="true">
            <Banner v-if="page.banner" :src="page.banner.src" :clipped="false" height="200px" />
            <div>
                <v-alert v-if="error" type="error" dismissible>
                    <p class="white--text">{{ error }}</p>
                </v-alert>
                <v-alert v-else-if="message" dismissible type="success">
                    <p class="white--text">{{ message }}</p>
                </v-alert>
            </div>

            <v-form  style="width: 100%;" ref="form">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            v-model.trim="authForm.firstname"
                            :rules="rules.firstname"
                            :counter="30"
                            label="Primeiro Nome"
                            data-vv-name="firstname"
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            v-model.trim="authForm.lastname"
                            :rules="rules.lastname"
                            :counter="30"
                            label="Apelido"
                            data-vv-name="lastname"
                            required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            v-model.trim="authForm.username"
                            :rules="rules.username"
                            :counter="150"
                            label="Username"
                            data-vv-name="username"
                            required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            v-model.trim="authForm.email"
                            :rules="rules.email"
                            type="email"
                            :counter="254"
                            label="Email"
                            data-vv-name="email"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            type="password"
                            v-model="authForm.password"
                            :rules="rules.password"
                            label="Password"
                            data-vv-name="password"
                            required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            type="password"
                            v-model="authForm.passwordConfirm"
                            :rules="[v => authForm.password === v || 'Password deve combinar.']"
                            label="Confirmar Password"
                            data-vv-name="password-confirm"
                            required></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-checkbox 
                        v-model="authForm.acceptTermsCondition"
                        label="Li e aceito os Termos de Uso"></v-checkbox>
                </v-row>
                <v-col cols="12">
                    <v-btn
                        @click="submit"
                        type="button"
                        color="primary"
                        :disabled="loading"
                        :loading="loading">
                        <span class="black--text">Sign Up</span>
                    </v-btn>
                    <span> -- ou -- </span>
                    <v-btn @click="emitCloseDialog"
                         color="red" :disabled="loading">
                        <span class="black--text">Sair</span>
                    </v-btn>
                </v-col>
            </v-form>
        </PostContainer>
    </div>
</template>
<script>
import { PAGE_KEYS } from '../utils';
import Banner from './Banner';
import PostContainer from './PostContainer';

import { mapActions, mapState } from 'vuex';

import { LOGIN } from '../routes';

const RULES = {
    required: (field) => v => !!v || `${field} é obrigatório.`,
    max: (field, len) => v => !v || v.length <= len || `${field} pode ter no máximo ${len} caracteres.`,
    min: (field, len) => v => (v && v.length >= len) || `${field} pode ter no mínimo ${len} caracteres.`,
    username: () => v => /^\w+(-?\w+)*$/.test(v) || `Apenas A-Z,a-z,0-9 e hífen como separador são permitidos.`,
};

export default {
    /*$_veeValidate: {
        validator: 'new',
    },*/
    data: () => ({
        loginRoute: LOGIN,
        authForm: {
            firstname: null,
            lastname: null,
            email: null,
            username: null,
            password: null,
            passwordConfirm: null,
            acceptTermsCondition: false,
        },
        rules: {
            firstname: [RULES.required('Primeiro Nome'), RULES.max('Primeiro Nome', 30)],
            lastname: [RULES.required('Apelido'), RULES.max('Apelido', 30)],
            email: [ RULES.required('Email'), RULES.max('Email', 254)],
            username: [RULES.required('Username'), RULES.max('Username', 150), RULES.username()],
            password: [RULES.required('Password'), RULES.min('Password', 6), RULES.max('Password', 16)],
            passwordConfirm: null,
        },
        error: null,
        message: null,
        page: {
            banner: null,
            text: null
        },
        loadingPage: false,
    }),
    created() {
        this.loadingPage = true;
        this.loadPage({ key : PAGE_KEYS.signUp }).then((function (page) {
            this.page = page;
        }).bind(this)).catch(() => {
        }).finally(() => this.loadingPage = false)
    },
    methods: {
        ...mapActions(['loadPage']),
        ...mapActions('auth', ['signUp']),
        submit() {
            this.error = null;
            this.message = null;

            if (this.$user.isAuthenticated) {
                this.error = 'Já se encontra autenticado.'
                return;
            }

            if (!this.authForm.acceptTermsCondition) { 
                this.error = "* Termos de uso.";
                return;
            }

            if (!this.$refs.form.validate()) return;
            this.signUp(this.authForm).then((state) => {
                if (state.success) {
                    this.message =  'Operação realizado com sucesso.  Vais receber um email para a confirmação';
                    this.$refs.form.reset();
                    this.valid = true;
                } else {
                    this.error = state.error;
                }
            }).catch(() => {
                this.error = 'Problema ao registar utilizador';
            });
        },
        cancel() {

        },

        emitCloseDialog() {
            this.$emit('close');
        },

        clean() {
            this.$refs.form.reset();
        },
    },
    computed: mapState('auth', ['loading']),
    components: {
        Banner,
        PostContainer,
    }
}
</script>