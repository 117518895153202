import festivalRequest from './festival-request';
import festival from './festival';
import jury from './jury';


export default {
    strict: true,
    namespaced: true,
    modules: {
        jury: jury,
        festivalRequest,
        myFestival: festival,
    },
    state: {
    },
    mutations: {
    },
    actions: {
       
    },
    getters: {
       
    }
};