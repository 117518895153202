<template>
    <div>
        <PostContainer :headless="true">
            <Banner v-if="page.banner" :src="page.banner.src" :clipped="false" height="200px"/>
            <v-alert v-if="error" type="error">
                <p class="white--text">{{ error }}</p>
            </v-alert>
            <v-alert v-else-if="message" type="success">
                <p class="white--text">{{ message }}</p>
            </v-alert>
            <v-form style="width: 100%;" ref="form">
                <v-col>
                    <v-text-field
                        outlined dense
                        v-model="authForm.username"
                        :rules="[ v => !!v || 'Campo obrigatório']"
                        :counter="150"
                        label="Username"
                        data-vv-name="username"
                        required
                        v-on:keyup.enter="submit"></v-text-field>
                </v-col>

                <v-col>
                    <v-text-field
                        outlined dense
                        type="password"
                        v-model="authForm.password"
                        v-on:keyup.enter="submit"
                        :rules="[ v => !!v || 'Campo obrigatório']"
                        label="Password"
                        data-vv-name="password"
                        required></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0 text-right">
                    <v-btn small text depressed :href="resetRoute" color="primary">
                        Esqueceu de password da conta?
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        @click="submit"
                        color="primary" small
                        :disabled="loading"
                        :loading="loading">
                        <span class="black--text">Login</span>
                        <template v-slot:loader>
                            <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                            </span>
                        </template>
                    </v-btn>
                    <v-btn
                        @click="emitCloseDialog" :disabled="loading"
                        class="error ml-2" color="danger" outlined small>
                        <span class="black--text">sair</span>
                    </v-btn>
                </v-col>
            </v-form>
        </PostContainer>
    </div>
</template>
<script>
import { PAGE_KEYS } from '../utils';
import Banner from './Banner';
import PostContainer from './PostContainer';

import { mapActions, mapState } from 'vuex';

import { ACCOUNT } from '../routes';

import { SESSION_ID } from '../utils';
export default {
    
    data: () => ({
        resetRoute: ACCOUNT.reset,
        authForm: {
            username: null,
            password: null,
        },
        error: null,
        message: null,
        page: {
            banner: null,
            text: null
        },
        loadingPage: false,
    }),
    created() {
        //this.loadAbout({ code: 'quem-somos' });
        this.loadingPage = true;
        this.loadPage({ key : PAGE_KEYS.login }).then((function (page) {
            this.page = page;
        }).bind(this)).catch(() => {
        }).finally(() => this.loadingPage = false)
    },
    computed: mapState('auth', ['loading']),
    methods: {
        ...mapActions('auth', ['signIn']),
        ...mapActions(['loadPage']),
        submit() {
            this.error = null;
            this.message = null;

            if (this.$user.isAuthenticated) {
                this.error = 'Já se encontra autenticado.'
                return;
            }

            if (!this.$refs.form.validate()) return;
            this.signIn(this.authForm).then((state) => {
                if (state.success) {
                    this.$session.start();
                    this.$session.set(SESSION_ID, state.user);
                    //this.$router.push({ path: PROFILE });
                    this.authForm.username = null;
                    this.authForm.password = null;
                    this.$emit('loginSuccess');
                    
                } else {
                    this.error = state.error;
                }
            }).catch(() => {
                this.error = 'Problema no pedido.';
            });
        },
        cancel() {

        },
        emitCloseDialog() {
            this.$emit('close');
        },
        clean() {
            this.$refs.form.reset();
        },
    },
    components: {
        Banner,
        PostContainer,
    }
}
</script>