var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.drawer)?_c('div',{attrs:{"rounded":"lg","offset-y":"","light":true}},[_c('v-btn',{style:(("" + ('padding-left: 0'))),attrs:{"small":"","text":""},on:{"click":function($event){return _vm.toggleDrawerMySpace(!_vm.mySpace)}}},[_c('v-icon',{attrs:{"color":'primary'}},[_vm._v("mdi-account-circle")]),_c('span',{class:'white--text pl-1'},[_vm._v("Meu Espaço")])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.mySpace),expression:"mySpace"}],attrs:{"dense":""}},[_vm._l((_vm.items),function(item,i){return [_c('v-list-item',{key:item.menu,attrs:{"dense":"","link":"","to":item.path}},[_c('v-list-item-title',{class:("" + ('white--text')),domProps:{"textContent":_vm._s(item.menu)}})],1),((i+1) < _vm.items.length)?_c('v-divider',{key:i,staticClass:"grey lighten-1",attrs:{"dense":""}}):_vm._e()]})],2)],1)],1):_c('v-menu',{attrs:{"rounded":"lg","offset-y":"","light":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":'black'}},[_vm._v("mdi-account-circle")]),_c('span',{class:'black--text'},[_vm._v("Meu Espaço")])],1)]}}])},[_c('v-list',{staticClass:"menu-dropdown",attrs:{"dense":""}},[_vm._l((_vm.items),function(item,index){return [_c('v-list-item',{key:index+'-li',staticClass:"menu-item"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('router-link',{attrs:{"to":item.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
var isActive = ref.isActive;
return [_c('v-btn',{class:isExactActive || (isActive && href !== '/') ? 'active' : '',attrs:{"text":"","small":"","color":"black","to":href}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.menu))])])]}}],null,true)})],1)],1),((index+1) < _vm.items.length)?_c('v-row',{key:index+'-d',attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('div',{staticClass:"menu-divider ml-2",staticStyle:{"width":"80%"}})]):_vm._e()]})],2)],1),(_vm.drawer)?_c('br'):_vm._e(),_c('v-btn',{staticClass:"elevation-0",class:("" + (!_vm.drawer ? 'd-none d-sm-inline-flex ma-2 ml-10' : ' mt-2')),attrs:{"height":"30","width":"20"},on:{"click":_vm.signOutUser}},[_c('span',{staticClass:"white--text"},[_vm._v("\n            Sair\n        ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }