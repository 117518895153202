<template>
    <div>
        <div v-if="drawer"
            rounded="lg"
            offset-y
            :light="true"
        >
            <v-btn @click="toggleDrawerMySpace(!mySpace)"
                small text
                :style="`${'padding-left: 0'}`"
            >
                <v-icon :color=" 'primary'">mdi-account-circle</v-icon>
                <span :class="'white--text pl-1'">Meu Espaço</span>
            </v-btn>

            <v-row align="center" justify="center">
            <v-list v-show="mySpace" dense>
                <template v-for="(item, i) in items">
                <v-list-item dense
                    
                    :key="item.menu"
                    link
                    :to="item.path"
                >
                    <v-list-item-title
                        v-text="item.menu"
                        :class="`${'white--text'}` "
                        ></v-list-item-title>

                       
                        
                </v-list-item>
                <v-divider v-if="(i+1) < items.length" :key="i" dense class="grey lighten-1"></v-divider>
                </template>
            </v-list>
            </v-row>
        </div>

        <v-menu v-else
            rounded="lg"
            offset-y
            :light="true"
        >
            <template v-slot:activator="{ attrs, on }">
                <v-btn 
                     small text
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon :color="'black'">mdi-account-circle</v-icon>
                    <span :class=" 'black--text'">Meu Espaço</span>
                </v-btn>
            </template>

            
            <v-list class="menu-dropdown" dense>
                <template v-for="(item, index) in items">
                    <v-list-item :key="index+'-li'" class="menu-item">
                        
                        <v-row justify="center" align="center" no-gutters>
                            <router-link
                                :to="item.path"
                                v-slot="{ href, isExactActive, isActive }">
                                <v-btn 
                                    text small color="black"
                                    :class="isExactActive || (isActive && href !== '/') ? 'active' : ''"
                                    :to="href" ><span class="black--text">{{ item.menu }}</span></v-btn>
                            </router-link>
                        </v-row>
                        
                    </v-list-item>
                     <v-row v-if="(index+1) < items.length" :key="index+'-d'"  justify="center" align="center" no-gutters>
                        <div 
                            class="menu-divider ml-2" 
                            style="width: 80%;" ></div>
                     </v-row>
                </template>
            </v-list>
        </v-menu>
        <!--v-chip small :to="auth.profile"
            class="ma-2" :color="drawer ? 'white' : 'black'">
            <span :class="!drawer ? 'white--text' : 'black--text'">{{firstName}}</span>
        </v-chip-->
        <br v-if="drawer">
        <v-btn 
            height="30" 
            width="20" 
            class="elevation-0" :class="`${!drawer ? 'd-none d-sm-inline-flex ma-2 ml-10' : ' mt-2'}`" @click="signOutUser">
            <span 
                class="white--text">
                Sair
            </span>
        </v-btn>
    </div>
</template>
<script>
import { PROFILE, CONTROL_PANEL, JURY_PANEL,HOME } from '../../routes';
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        drawer: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        mySpace: false,
        auth: {
            profile: PROFILE
        },
        items: [
            { menu: "PERFIL", path: PROFILE, divider: false },
            { menu: "PAINEL CONTROLO", path: CONTROL_PANEL.home, divider: true },
            //{ menu: "PAINEL JÚRI", path: JURY_PANEL.home, divider: true },
            { menu: "SUBMISSÕES", path: CONTROL_PANEL.dashboard.subscriptions, divider: true },
            { menu: "PROJETOS", path: CONTROL_PANEL.projects.index, divider: false }
        ]
    }),
    computed: {
        firstName() {
            return this.$user.info.firstName;
        },
        isFestivalProfile() {
            return this.$user.info.isFestivalProfile;
        }
    },

    mounted() {
        if (this.isFestivalProfile)
            this.items.push({ menu: "PAINEL JÚRI", path: JURY_PANEL.home, divider: true })
    },

    methods: {
        ...mapMutations('auth', ['authenticated']),
        ...mapActions('auth', ['signOut']),
        signOutUser() {
            this.signOut().then((success) => {
                if (success) {
                    this.$user.logout();
                    this.authenticated(false);
                    this.$router.push({ path: HOME });
                }
            });
        },
        toggleDrawerMySpace(val) {
            this.mySpace = val;
        }
    }
}
</script>