import { PALOPTL, FILE_URL } from '../../utils/api';
import { auth } from '../../gql';
import { getEmptyOrUsefulName } from '../../utils';

export default {
    strict: true,
    namespaced: true,
    state: {
        loading: false,
        loadingConfirmation: false,
        loadingReset: false,
    },
    mutations: {
        loading(state, payload) {
            state.loading = payload;
        },
        loadingConfirmation(state, payload) {
            state.loadingConfirmation = payload;
        },
        loadingReset(state, payload) {
            state.loadingReset = payload;
        },
    },
    actions: {
        async signOut() {
            return true;
        },
        async signIn({ commit, state }, payload) {
            if (state.user) {
                return {
                    success: false,
                    error: 'Utilizador já encontra autenticado.'
                };
            }
            commit('loading', true);
            const variables = { 
                input: {
                  username: payload.username || '', 
                  password: payload.password || '', 
                }
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: auth.signIn,
                    variables,
                });
                const signIn = res.data.data.signIn;
                const profile = signIn.profile;
                if (profile == null) {
                    commit('loading', false);
                    return {
                        success: false,
                        error: signIn.status.error,
                    };
                }
                commit('loading', false);
                return  {
                    success: true,
                    user: toUser(profile),
                    message: signIn.status.success,
                };
            } catch(err) {
                commit('loading', false);
                return {
                    success: false,
                    error: 'Problema no pedido.'
                };
            }
        },
        async signUp({ commit, state }, payload) {
            if (state.user) {
                return {
                    success: false,
                    error: 'Estás com login ativado.'
                };
            }
            commit('loading', true);
            const variables = { 
                input: {
                  username: (payload.username || '').trim(), 
                  password: payload.password, 
                  email: (payload.email || '').trim(), 
                  firstName: (payload.firstname || '').trim(), 
                  lastName: (payload.lastname || '').trim(), 
                  acceptedLicence: payload.acceptTermsCondition,
                }
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: auth.signUp,
                    variables,
                });
                const signup = res.data.data.signup;
                const profile = signup.profile;
                if (profile == null) {
                    commit('loading', false);
                    return {
                        success: false,
                        error: signup.status.error,
                    };
                }
                commit('loading', false);
                return {
                    success: signup.status.success === 'true',
                    message: signup.status.error,
                };
            } catch(err) {
                commit('loading', false);
                return {
                    success: false,
                    message: 'Falha no pedido.',
                };
            }
        },
        async confirmSignUp({ commit }, payload) {
            /*if (state.user) {
                return {
                    success: false,
                    error: 'Estás com login ativado.'
                };
            }*/
            commit('loadingConfirmation', true);
            const variables = { 
                input: {
                  token: payload.token || '', 
                  uid: payload.uid || '', 
                }
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: auth.confirmSignUp,
                    variables,
                });

                const confirmSignup = res.data.data.confirmSignup;
                
                commit('loadingConfirmation', false);
                return {
                    success:  confirmSignup.status.success === 'true',
                    message:  confirmSignup.status.error,
                };
            } catch(err) {
                commit('loadingConfirmation', false);
                return {
                    success: false,
                    message: 'Falha no pedido.',
                };
            }
        },
        async resetPassword({ commit }, payload) {
            /*if (state.user) {
                return {
                    success: false,
                    error: 'Estás com login ativado.'
                };
            }*/
            commit('loadingReset', true);
            const variables = { 
                input: {
                  email: payload.email || '', 
                }
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: auth.resetProfilePassword,
                    variables,
                });

                const r = res.data.data.resetProfilePassword;
                
                commit('loadingReset', false);
                return {
                    success:  r.status.success === 'true',
                    message:  r.status.error,
                };
            } catch(err) {
                commit('loadingReset', false);
                return {
                    success: false,
                    message: 'Falha no pedido.',
                };
            }
        },
        async confirmResetProfilePassword({ commit }, payload) {
            /*if (state.user) {
                return {
                    success: false,
                    error: 'Estás com login ativado.'
                };
            }*/
            commit('loadingReset', true);
            const variables = { 
                input: {
                  password: payload.password,
                  token: payload.token || '', 
                  uid: payload.uid || '', 
                }
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: auth.confirmResetProfilePassword,
                    variables,
                });

                const r = res.data.data.confirmResetProfilePassword;
                
                commit('loadingReset', false);
                return {
                    success:  r.status.success === 'true',
                    message:  r.status.error,
                };
            } catch(err) {
                commit('loadingReset', false);
                return {
                    success: false,
                    message: 'Falha no pedido.',
                };
            }
        },
        
    },
    getters: {
        
    }
}


function toUser(node) {
    if (!node) {
        return null;
    }

    return {
        id: node.id,
        username: node.username,
        firstName: node.firstName,
        isFestivalProfile: node.isFestivalProfile,
        avatar: node.profilephotos ? FILE_URL + '/' + node.profilephotos.avatar : null,
        lastName: getEmptyOrUsefulName(node.lastName),
        uid: node.uid,
        get fullName() {
            return `${this.firstName} ${this.lastName}`;
        },
    };
} 