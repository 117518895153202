<template>
    <span>
        <v-btn 
            class="text-primary  mr-4" small text
            @click="emitOpenDialog"><span :class="drawer ? 'white--text' : 'black--text'">Login</span></v-btn> 
        <v-btn
            @click="emitOpenSignUpDialog"
            small
            class="black--text"
            color="black">
            <span class="white--text">Sign Up</span>
        </v-btn>
    </span>
</template>
<script>
import { LOGIN, SIGN_UP } from '../../routes';

export default {
    props: {
        drawer: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        auth: {
            login: LOGIN,
            signUp: SIGN_UP,
        },
        dialog: true,
    }),
    methods: {
        emitOpenDialog() {
            this.$emit("openLogin");
        },
        emitOpenSignUpDialog() {
            this.$emit("openSignUp");
        }
    }
}
</script>