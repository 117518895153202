<template>
  <v-row justify="center" v-if="show">
    <v-dialog class="pa-0 ma-0" :value="show" fullscreen hide-overlay >
      <a @click="close">
      <div class="secondary" style="height: 100%;">
        <v-container style="height: 100%;">
            <v-row justify="center" align="center" style="height: 100%;">
              <v-col cols="12">
                  <v-img 
                        :src="page.banner ? page.banner.src : '/assets/img/REDE_Splash.png'" 
                        contain max-height="529" />
              </v-col>
            </v-row>
        </v-container>
      </div>
      </a>
    </v-dialog>
  </v-row>
</template>

<script>
import { PAGE_KEYS } from '../utils';
import { /*mapGetters,*/ mapActions } from 'vuex';
export default {
    props: {
        show: Boolean,
    },
    data: () => ({
        page: {
            banner: null,
            text: null
        },
        loadingPage: false,
    }),
    created() {
        //this.loadAbout({ code: 'quem-somos' });
        this.loadingPage = true;
        this.loadPage({ key : PAGE_KEYS.landingPage }).then((function (page) {
            this.page = page;
        }).bind(this)).catch(() => {
        }).finally(() => this.loadingPage = false)
    },
    methods: {
       ...mapActions(['loadPage']),
        close() {
            this.$emit('close');
        }
    }
}
</script>