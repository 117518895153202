import { PALOPTL, FILE_URL } from '../../../../utils/api';
import { auth } from '../../../../gql';
import { CONTROL_PANEL } from '../../../../routes';
import _ from 'lodash';
import moment from 'moment';

export default {
    strict: true,
    namespaced: true,
    state: {
        projects: [],
        loading: false,
        hasMore: false,
        project: null,
    },
    modules: {
    },
    mutations: {
        projects(state, payload) {
            state.projects = payload || [];
        },
        project(state, payload) {
            state.project = payload;
        },
        appendProjects(state, payload) {
            state.projects = _.concat(state.projects, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },

    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                id: payload.id,
                limit: payload.limit,
                cursor: payload.cursor,
            };
            const res = await PALOPTL().post('/', {
                query: auth.user.projects.list,
                variables,
            });
            const queryRes = res.data.data.author;

            const obj = {
                items:(queryRes.projects.edges || []).filter(e => (e.node || {}).status).map(toProject),
            }

            commit('loading', false);
            commit('hasMore', queryRes.projects.pageInfo.hasNextPage);

            return obj;
            
        },
        async list ({ commit, dispatch }, payload) {
            commit('loading', true);
            commit('projects', []);
            const projects = await dispatch('load', payload);
            commit('projects', projects.items);
            commit('loading', false);
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            const projects = await dispatch('load', { ...payload, cursor: getters.lastCursor});
            commit('appendProjects', projects.items);
        },
        async detail({ commit }, payload) {
            commit('loading', true);
            commit('project', {});
            const variables = {
                id: payload.id,
            };
            const res = await PALOPTL().post('/', {
                query: auth.user.projects.one,
                variables,
            });
            const queryRes = res.data.data.project;
            commit('loading', false);
            commit('project', toDetailedProject(queryRes, payload.forUpdate));
        },
    },
    
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.projects)) {
                return null;
            }
            const len = state.projects.length;
            return state.projects[len -1].$cursor;
        }
    }
}


function toProject(edge) {
    let node = edge && edge.node ? edge.node : {};
    //const media = toProjectMedia(node.medias.edges);
    return {
        id: node.id,
        title: node.name,
        src: node.cover ? FILE_URL+"/"+node.cover : null,//media ? FILE_URL+"/"+media : null,
        sinopse: node.sinopse,
        dataProducao: node.dataProducao,
        paisProducao: node.paisProducao ? node.paisProducao.id : null,
        dataProducaoFmt: node.dataProducao ? moment(node.dataProducao, 'YYYY-MM-DD').format('DD.MM.YYYY'): null,
        paisProducaoDescricao: node.paisProducao ? node.paisProducao.name : null,
        duration: 'N/D',
        credits: _.reduce(node.credits.edges, toProjectExtra, ''),
        $route: {
            view: `${CONTROL_PANEL.projects.index}/${node.id}`,
            update: `${CONTROL_PANEL.projects.update}/?id=${node.id}`,
        }
    };
} 

/*function toProjectMedia(edges) {
    if (!edges) {
        return null;
    }

    if (!edges[0]) {
        return null;
    }
    const node = edges[0].node;
    if (!node) {
        return null;
    }
    return node.coverPic;
}*/

function toProjectExtra(sum, edge) {
    const node = edge && edge.node ? edge.node : {};
    return `
    ${sum}
    <p>${node.name}</p>
    `
}



function toDetailedProject(node, forUpdate) {
    //const node = edge && edge.node ? edge.node : {};
   // const media = toProjectMedia(node.medias.edges);

    return {
        id: node.id,
        title: node.name,
        name: node.name,
        sinopse: node.sinopse,
        description: node.description,
        bio: node.bio,
        //src: media ? FILE_URL+"/"+media : null,
        src: node.cover ? FILE_URL+"/"+node.cover : null,
        banner: node.banner ? FILE_URL+"/"+node.banner : null,
        externalLink: node.externalLink,
        externLink: node.externalLink,
        awards: _.map(node.awards.edges, toDetailedProjectExtra),
        credits: _.map(node.credits.edges, toDetailedProjectExtra),
        category:  forUpdate ? node.category.id : node.category,
        dataProducao: node.dataProducao,
        dataProducaoFmt: node.dataProducao ? moment(node.dataProducao, 'YYYY-MM-DD').format('DD.MM.YYYY'): null,
        paisProducao: node.paisProducao ? node.paisProducao.id : null,
        paisProducaoDescricao: node.paisProducao ? node.paisProducao.name : null,
        obrafiles: [],
        isPublic: node.status,
    };
} 

function toDetailedProjectExtra(edge) {
    const node = edge && edge.node ? edge.node : {};
    return {
        id: node.id,
        name: node.name,
    };
}