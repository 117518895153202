import main from './main';
import auxiliary from './auxiliary';
import jury from './jury';
import festival from './festival';
import home from './home';





export default {
    strict: true,
    namespaced: true,
    modules: {
        main,
        auxiliary,
        jury,
        festival,
        home,
    },
    state: {
    },
    mutations: {
    },
    actions: {
       
    },
    getters: {
       
    }
};