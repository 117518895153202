<template>
    <div class="">
        <Autenticated v-if="user" :drawer="drawer"/>
        <Guest  v-else 
            @openLogin="emitOpenDialog" 
            @openSignUp="emitOpenSignUp" 
            :drawer="drawer"/>
    </div>
</template>
<script>

import Guest from './Guest';
import Autenticated from './Autenticated';


export default {
    props: {
        'user': {
            type: Object,
        },
        drawer: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Autenticated,
        Guest,
    },
    methods: {
        emitOpenDialog() {
            this.$emit('openLogin');
        },
        emitOpenSignUp() {
            this.$emit('openSignUp');
        }
    }
}
</script>