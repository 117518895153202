<template>
    <div v-if="item" style="display: inline"  :class="item.class">
        <router-link v-if="!item.children || item.children.length === 0"
            :to="item.route"
            v-slot="{ href, isExactActive, isActive }">
            <v-btn 
                :to="href"
                small
                text class="menu" :class="isExactActive || (isActive && href !== '/') ? 'active' : ''">
                <span class="black--text">{{ item.title }}</span>
            </v-btn>
        </router-link>
        <v-menu
            v-else
            :open-on-hover="true"
            :close-on-content-click="true"
            :offset-y="true">

            <template v-slot:activator="{ on }">
                <router-link
                    :to="item.route"
                    v-slot="{ href, isExactActive, isActive }">
                    <v-btn
                        :to="href"
                        class="menu hidden-md-and-down"
                        :class="isExactActive || (isActive && href !== '/') ? 'active' : ''"
                        text
                        small
                        router-link
                        v-on="on">
                        <span class="black--text">{{ item.title }}</span>
                    </v-btn>
                </router-link>
            </template>
            <v-list class="menu-dropdown" dense>
                <template v-for="(child, index) in item.children">
                    <v-list-item :key="index+'-li'" :class="'menu-item'">
                        
                        <v-btn 
                            text small color="black"
                            :to="child.route"><span class="black--text">{{ child.title }}</span></v-btn>
                        
                        
                    </v-list-item>
                    <div v-if="(index+1) < item.children.length" class="menu-divider ml-2" style="width: 80%;" :key="index+'-d'"></div>
                </template>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    props: ['item'],
}
</script>