import { 
    PALOPTL, FILE_UPLOADER, 
    SAVE_PROJECT_FILE, STORAGE_TYPE, STORAGE_UPLOAD } from '../../../utils/api';
import { auth } from '../../../gql';

import database from './database';

import _ from 'lodash';

export default {
    strict: true,
    namespaced: true,
    modules: {
        database,
     },
    state: {
        loading: false,
        loadingProjectCategories: false,
        processingProfileContact: false,
        processingProfileData: false,
        processingProject: false,
        projectCategories: [],
        hasMore: false,
        author: { projects: [] },
    },
    mutations: {
        author(state, payload) {
            state.author = payload;
        },
        loading(state, payload) {
            state.loading = payload;
        },
        projectCategories(state, payload) {
            state.projectCategories = payload || [];
        },
        loadingProjectCategories(state, payload) {
            state.loadingProjectCategories = payload;
        },
        processingProject(state, payload) {
            state.processingProject = payload;
        },
        processingProfileContact(state, payload) {
            state.processingProfileContact = payload;
        },
        processingProfileData(state, payload) {
            state.processingProfileData = payload;
        },
    },
    actions: {
        async detail({ commit }, payload) {
            commit('loading', true);
            commit('author', null);

            const variables = {
                id: payload.id,
            };
            const res = await PALOPTL().post('/', {
                query: auth.user.one,
                variables,
            });
            const queryRes = res.data.data.author;
            
            
            const one = toAuthor(queryRes);
            
            commit('loading', false);
            commit('author', one);
        },

        async loadProjectCategories({ commit }) {
            commit('loadingProjectCategories', true);
            commit('projectCategories', null);

            try {
                const res = await PALOPTL().post('/', {
                    query: auth.user.projectCategories,
                });
                const queryRes = res.data.data.projectCategories;
                const list = toProjectCategories(queryRes.edges);
            
                commit('loadingProjectCategories', false);
                commit('projectCategories', list);
            } catch(err) {
                commit('loadingProjectCategories', false);
            }
        },

        async createOrUpdateProject({ commit }, payload) {
            const data = payload.data;
            const token = payload.token;
            const update = !!data.id; 
            commit('processingProject', true);

            try {
                const input = {
                    name: data.name,
                    owner: token.id,
                    category: data.category,
                    bio: data.bio,
                    description: data.description,
                    sinopse: data.sinopse,
                    dataProducao: data.dataProducao,
                    paisProducao: data.paisProducao,
                    externLink: data.externLink,
                    creditList: (data.credits  || []).map(c => ({name: c.name})),
                    awards: (data.awards || []).map(a => ({name: a.name}))
                };

                let query;
                if (update) {
                    input['key'] = payload.data.id;
                    query = auth.user.mutation.project.update;
                } else {
                    query = auth.user.mutation.project.create;
                }

                const res = await PALOPTL().post('/', {
                    query: query,
                    variables: { input }
                });

                const queryRes = update ? 
                        res.data.data.updateProject : 
                        res.data.data.createProject;

                if (!queryRes.project) {
                    commit('processingProject', false);
                    return {
                        success: false,
                        error:  queryRes.status.error,
                    }
                }

                let fileMessage = '';
                const id = queryRes.project.id;
                try {
                    if (data.obrafiles) await uploadFile(
                        data.obrafiles, 
                        id, 
                        { fileInputName: 'file', elementInputName: 'project' }
                    );
                    
                    if (data.banner) await uploadStorage(
                        data.banner,
                        id,
                        'project.banner',
                        { fileInputName: 'file', elementInputName: 'element', typeStorageInputName: 'type'  }
                    );

                    if (data.cover) await uploadStorage(
                        data.cover,
                        id,
                        'project.cover',
                        { fileInputName: 'file', elementInputName: 'element', typeStorageInputName: 'type'  }
                    );
                } catch(err) {
                    throw err
                    //fileMessage = 'Ocorreu problema ao carregar ficheiro(s) da obra.'
                }

                commit('processingProject', false);

                return {
                    isUpdate: update,
                    success: true,
                    data: { ...data, obrafiles: [], id},
                    message: (queryRes.status.success === 'true' ? 'Operação Realizada com sucesso' : queryRes.status.success)+
                    '\n'+fileMessage,
                };
            } catch(err) {
                commit('processingProject', false);
                return {
                    isUpdate: update,
                    success: false,
                    error: 'Problema no processamento o pedido.',
                };
            }
        },


        async deleteProject({ commit }, payload) {
            const data = payload.data;
            //const token = payload.token;
            commit('processingProject', true);

            try {
                const input = {
                    key: data.id,
                };
                
                const res = await PALOPTL().post('/', {
                    query: auth.user.mutation.project.delete,
                    variables: { input }
                });

                const queryRes = res.data.data.deleteProject;

                commit('processingProject', false);

                return {
                    id: input.key,
                    success: queryRes.status.success !== 'false',
                    message: queryRes.status.error || queryRes.status.success,
                };
            } catch(err) {
                commit('processingProject', false);
                return {
                    success: false,
                    error: 'Problema no processamento do pedido.',
                };
            }
        },

        async updateProfileContact({ commit }, payload) {
            const data = payload.data;
            const token = payload.token;
            commit('processingProfileContact', true);

            try {
                const input = {
                    key: token.id,
                    instagramUsername: data.instagram,
                    facebookPage: data.facebook,
                    website: data.website,
                    youtubeLink: data.youtube,
                    phoneNumber: data.phone,
                };
                
                const res = await PALOPTL().post('/', {
                    query:  auth.user.mutation.profile.contact.update,
                    variables: { input }
                });

                const queryRes = res.data.data.updateProfileContact;
                

                commit('processingProfileContact', false);

                const success = queryRes.status.success === 'true';
                return {
                    success,
                    message: queryRes.status.error,
                };
            } catch(err) {
                commit('processingProfileContact', false);
                return {
                    success: false,
                    error: 'Problema no processamento do pedido.',
                };
            }
        },


        async updateProfileData({ commit }, payload) {
            const data = payload.data;
            const token = payload.token;
            commit('processingProfileData', true);

            try {
                const input = {
                    key: token.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    
                    username: data.username, //TODO must be controlled by user password
                    email: data.email, //TODO must be controlled by user password
                    
                    isHistorical: data.isHistorical,
                    isActive: true, //data.isActive -- user cant control this
                    
                    address: data.city,
                    subcategories: data.subcategories,

                    professionalBio: data.bio.professional,
                    personalBio: data.bio.personal,
                    externalLink: data.bio.rel,
                    mentionedIn: data.bio.quote,
                };
                
                const res = await PALOPTL().post('/', {
                    query:  auth.user.mutation.profile.data.update,
                    variables: { input }
                });

                let fileMessage = '';

                try {
                    await uploadStorage(
                        data.photo, 
                        token.id, 
                        STORAGE_TYPE.PROFILE,
                        { fileInputName: 'file', elementInputName: 'element', typeStorageInputName: 'type'  }
                    );
                } catch(err) {
                    fileMessage = ' Ocorreu problema ao carregar foto de perfil.'
                }


                try {
                    await uploadStorage(
                        data.bio.cover, 
                        token.id, 
                        STORAGE_TYPE.PORTFOLIO_COVER,
                        { fileInputName: 'file', elementInputName: 'element', typeStorageInputName: 'type'  }
                    );
                } catch(err) {
                    fileMessage += ' Ocorreu problema ao carregar foto de capa.'
                }

                const queryRes = res.data.data.updateProfile;

                commit('processingProfileData', false);

                const success = queryRes.status.success === 'true';
                return {
                    success,
                    message: queryRes.status.error+fileMessage,
                };
            } catch(err) {
                commit('processingProfileData', false);
                return {
                    success: false,
                    error: 'Problema no processamento do pedido.',
                };
            }
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.authors)) {
                return null;
            }
            const len = state.authors.length;
            return state.authors[len -1].$cursor;
        }
    }
}

function toAuthor(node) {
    if (node == null) {
        return null;
    }

    const contact = node.contact || {};
    const portfolio = getPortfolio(node.portfolios.edges);
    const projects = _.map(node.projects.edges, toProject);
    const photos = node.profilephotos ? node.profilephotos : {}
    return {
        info: {
            id: node.id,
            email: node.email,
            avatar: photos.avatar,
            username: node.username,
            firstName: node.firstName,
            lastName: node.lastName,
            bio: portfolio,
            subcategories: _.map(node.subcategories.edges, (e) => e.node.id),
            city: node.address ? node.address.id : null,
            isHistorical: node.isHistorical,
        },
        projects,
        get fullName() {
            return `${this.firstName} ${this.lastName}`;
        },
        contacts: {
            phone: contact.phoneNumber,
            website: contact.website,
            facebook: contact.facebookPage,
            instagram: contact.instagramUsername,
            youtube: contact.youtubeLink,
            
        },
        //subcategories: _.map(node.subcategories.edges, (e) => e.node.id),
    }
}



function getPortfolio(edges) {
    if (!edges || edges.length == 0) {
        return {
            quote: null,
            rel: null,
            personal: null,
            professional: null,
        };
    }
    const node = edges[0].node;
    return {
        quote: node.mentionedIn,
        rel: node.externalLink,
        personal: node.personalBio,
        professional: node.professionalBio
    };
}

function toProject(edge) {
    const node = edge && edge.node ? edge.node : {};
    return {
        id: node.id,
        name: node.name,
        sinopse: node.sinopse,
        description: node.description,
        bio: node.bio,
        externLink: node.externalLink,
        awards: _.map(node.awards.edges, toProjectExtra),
        credits: _.map(node.credits.edges, toProjectExtra),
        category: node.category.id,
        obrafiles: [],
        isPublic: node.status,
    };
} 

function toProjectExtra(edge) {
    const node = edge && edge.node ? edge.node : {};
    return {
        id: node.id,
        name: node.name,
    };
}

function toProjectCategories(edges) {
    const list = edges || [];
    const data = [];
    list.map(c1 => c1.node).forEach(c1 => {
        (c1.subcategories.edges || []).map(c2 => c2.node).forEach(c2 => {
            (c2.subsubcategories.edges || []).map(c3 => c3.node).filter(c3 => c3.status).forEach(c3 => {
                data.push({
                    id: c3.id,
                    name: c3.name,
                    category: c1.name,
                    subcategory: c2.name
                });
            })
        })
    });
    return data;
}


async function uploadFile(files, elementId, { fileInputName, elementInputName }) {
    let error = 0;
    if (!_.isEmpty(files)) {
        _.forEach(files, async file => {
            let fd = new FormData()
            fd.append(elementInputName, elementId)
            fd.append(fileInputName, file)

            try {
                const res = await FILE_UPLOADER(
                    {
                        "Content-Type": "multipart/form-data"
                    }
                ).post(SAVE_PROJECT_FILE, fd)
                if (!res.data.data) {
                    error +=1;
                }
            } catch(err) {
                error +=1;
            }
        })
    }

    if (error > 0) {
        throw Error('Error processing file');
    } 
}


async function uploadStorage(file, elementId, type, { fileInputName, elementInputName, typeStorageInputName }) {
    if (file) {
        let fd = new FormData()
        fd.append(elementInputName, elementId)
        fd.append(fileInputName, file)
        fd.append(typeStorageInputName, type)


        const res = await FILE_UPLOADER(
            {
                "Content-Type": "multipart/form-data"
            }
        ).post(STORAGE_UPLOAD, fd)

        if (!res.data.data) {
            throw Error('Error processing file');
        }
    }
}