import { PALOPTL, FILE_URL } from '../../utils/api';
import { news } from '../../gql';
import { NEWS } from '../../routes';

import _ from 'lodash';
import moment from 'moment';

export default {
    strict: true,
    namespaced: true,
    state: {
        loading: false,
        hasMore: false,
        news: [],
        newsDetail: null,
    },
    mutations: {
        news(state, payload) {
            state.news = payload || [];
        },
        newsDetail(state, payload) {
            state.newsDetail = payload;
        },
        appendNews(state, payload) {
            state.news = _.concat(state.news, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        }
    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
            };
            const res = await PALOPTL().post('/', {
                query: news.all,
                variables,
            });
            const newsRes = res.data.data.allNews;
            const allNews = newsRes.edges.map(toPost);
            
            commit('loading', false);
            commit('hasMore', newsRes.pageInfo.hasNextPage);

            return allNews;
        },
        async list ({ commit, dispatch }, payload)  {
            const news = await dispatch('load', payload);
            commit('news', news);
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            const news = await dispatch('load', { ...payload, cursor: getters.lastCursor});
            commit('appendNews', news);
        },
        async detail({ commit }, payload) {
            commit('loading', true);
            commit('newsDetail', {});
            const variables = {
                id: payload.id,
            };
            const res = await PALOPTL().post('/', {
                query: news.one,
                variables,
            });
            const newsRes = res.data.data.oneNews;
            commit('loading', false);
            commit('newsDetail', toDetailedPost(newsRes));
        },

        async markNewsAsViewd(_, payload) {
            const variables = {
                    input: { news: payload.id},
            };
            try {
                await PALOPTL().post('/', {
                    query: news.mutation.view,
                    variables,
                });
            } catch (error) {
                return null;
            }
        },
    },
    getters: {
        homePageNews(state) {
            return _.slice(state.news, 0, 6);
        },
        lastCursor(state) {
            if (_.isEmpty(state.news)) {
                return null;
            }
            const len = state.news.length;
            return state.news[len -1].$cursor;
        }
    }
}


function toPost(edge) {
    const node = edge.node;
    return {
        id: node.id,
        title: node.title,
        description: node.sinopse,
        src: node.coverPic ? `${FILE_URL}/${node.coverPic}` : null,
        comments: 0,
        favorites: 0,
        date: moment(node.dataPublished).format('DD • MM • YYYY, HH:mm:ss'), //moment(node.dataPublished).fromNow(),//format('DD • MM • YYYY'),
        $cursor: edge.cursor,
        $route: {
            path: NEWS+`/${node.id}`,
        }
    };
} 

function toDetailedPost(node) {
    if (!node) {
        return null;
    }
    return {
        id: node.id,
        title: node.title,
        subTitle: node.subTitle,
        sinopse: node.sinopse,
        body: node.body,
        src: node.coverPic ? `${FILE_URL}/${node.coverPic}` : null,
        comments: 0,
        favorites: 0,
        date: moment(node.dataPublished).format('DD • MM • YYYY'),
    };
} 