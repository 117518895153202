import {PALOPTL, GQL_API, FILE_URL } from '../../../utils/api';
import { dashboard } from '../../../gql';
import _ from 'lodash';


// Festival Profile Request
export default {
    strict: true,
    namespaced: true,
    state: {
        items: [],
        loading: false,
        item:null,
        processing: false,
        hasMore: false
    },
    mutations: {
        items(state, payload) {
            state.items = payload || [];
        },
        item(state, payload) {
            state.item = payload;
        },
        appendItems(state, payload) {
            state.items = _.concat(state.items, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        processing(state, payload) {
            state.processing = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
    },

    actions: {

        async detail({ commit }/*, payload*/) {
            commit('loading', true);
            commit('item', null);

            /*const variables = {
                id: payload.id
            };
            const res = await PALOPTL().post(GQL_API, {
                query: festivalRequestProfile.detail,
                variables,
            });
            const queryRes = res.data.data.request;
            */
            
            const one = toItem({node: null /*queryRes*/});
            
            commit('loading', false);
            commit('item', one);
        },
        async load({ commit }, payload) {
            commit('loading', true);
            
            const variables = {
                festival: payload.festival,
                "status": "pendding",
                "judge_Category": "Auxiliar",
                "subscription_status": "pendente"
            };

            const res = await PALOPTL().post(GQL_API, {
                query: dashboard.juryPanel.jury.reviews,
                variables: { ...variables },
            });

            const queryRes = res.data.data.reviews;
            const all = queryRes.edges.map(toItem);
            commit('loading', false);
            commit('hasMore', queryRes.pageInfo.hasNextPage);

            return all;
        },

        async list ({ commit, dispatch }, payload)  {
            try {
                const items = await dispatch('load', payload);
                commit('items', items);
            } catch (err) {
                commit('loading', false);
            }
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            try {
                const items = await dispatch('load', { ...payload, cursor: getters.lastCursor});
                commit('appendItems', items);
            } catch(err) {
                commit('loading', false);
            }
        },

        async submitReview({ commit }, payload) {
            const data = payload.data;
            const update = false; 
            //const token = payload.token
            commit('processing', true);

            try {
                const input = {
                    ...data,
                };

                let query;
                if (update) {
                    query = null; // avante
                } else {
                    query = dashboard.juryPanel.jury.submitReview;
                }
                
                const res = query ? await PALOPTL().post(GQL_API, {
                    query: query,
                    variables: { input }
                }) : {
                    request: { id: payload.data.id }, 
                    status: { message: 'Não foi feito request', success: false} 
                };

                const queryRes = update ? 
                        res : 
                        res.data.data.submitJury2Review;

                if (!queryRes.review) {
                    commit('processing', false);
                    return { 
                        isUpdate: update,
                        success: false,
                        messages: [{
                            success: false,
                            message: queryRes.status.message,
                        }]
                    };
                }
                
                const id = queryRes.review.id;
                
                commit('processing', false);

                return {
                    isUpdate: update,
                    success: queryRes.status.success ,
                    data: { ...data, id },
                    messages: [{
                        success: queryRes.status.success,
                        message: queryRes.status.message
                    }],
                };
            } catch(err) {
                commit('processing', false);
                alert(err)
                return {
                    success: false,
                    isUpdate: update,
                    messages: [{
                        success: false,
                        message: 'Problema no processamento do pedido.',
                    }]
                };
            }
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.requests)) {
                return null;
            }
            const len = state.requests.length;
            return state.requests[len -1].$cursor;
        }
    }
}


function toItem(edge) {
    const node = edge.node;
    if (!node) return null;

    return {
        status: node.status,
        "$cursor": node.cursor,
        reviews: {
            id: node.id,
            review: (JSON.parse(node.reviews).reviews || []).map(r => {
                return {
                    ...r,
                    status: r.status.charAt(0)
                }
            })
        },
        subscription: {
            ...node.subscription,
            project: {
                ...node.subscription.project,
                src: FILE_URL + '/' + node.subscription.project.cover,
            }
        }
    }
}
