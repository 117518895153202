import Vue from 'vue';
import { SESSION_ID } from '../utils';

const PalopAuth = {

};

PalopAuth.install = function (Vue) {
    Vue.prototype.$user = Object.freeze({
        get isGuest() {
            return !this.isAuthenticated;
        },

        get isAuthenticated() {
            return Vue.prototype.$session.has(SESSION_ID);
        },

        get info() {
            return Vue.prototype.$session.get(SESSION_ID);
        },

        logout() {
            Vue.prototype.$session.destroy()
        }
    });
}

Vue.use(PalopAuth);