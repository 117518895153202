import { PALOPTL } from '../../../utils/api';
import { festival } from '../../../gql';
import festivalModule from './modules/festival';
import { FESTIVAL } from '../../../routes';
import _ from 'lodash';

export default {
    strict: true,
    namespaced: true,
    modules: {
        festival: festivalModule,
    },
    state: {
        loading: false,
        hasMore: false,
        categories: [],
    },
    mutations: {
        categories(state, payload) {
            state.categories = payload || [];
        },
        appendCategories(state, payload) {
            state.categories = _.concat(state.categories, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        }
    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
            };
            const res = await PALOPTL().post('/', {
                query: festival.category.all,
                variables,
            });
            const catRes = res.data.data.festivalCategories;
            const allCategory = catRes.edges.map(toPost);
            
            commit('loading', false);
            commit('hasMore', catRes.pageInfo.hasNextPage);

            return allCategory;
        },
        async list ({ commit, dispatch }, payload)  {
            const categories = await dispatch('load', payload);
            commit('categories', categories);
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            const categories = await dispatch('load', { ...payload, cursor: getters.lastCursor});
            commit('appendCategories', categories);
        }
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.categories)) {
                return null;
            }
            const len = state.categories.length;
            return state.categories[len -1].$cursor;
        }
    }
}


function toPost(edge) {
    const node = edge.node;
    return {
        id: node.id,
        title: node.name,
        src: '/assets/img/plholder.jpg',
        $cursor: edge.cursor,
        $route: { path: FESTIVAL, query: { tipo: node.id } }
    };
} 