import { PALOPTL } from '../../../../utils/api';
import { project } from '../../../../gql';
import { PROJECT } from '../../../../routes';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        loading: false,
        hasMore: false,
        status: null,
        category: null,
        subcategories: [],
        loadingCategories: false,
        projects: [],
        project: null,
        projectsToSubscribe: []
    },
    mutations: {
        category(state, payload) {
            state.category = payload;
        },
        projects(state, payload) {
            state.projects = payload || [];
        },
        project(state, payload) {
            state.project = payload;
        },
        appendProjects(state, payload) {
            state.projects = _.concat(state.projects, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
        subcategories(state, payload) {
            state.subcategories = payload || [];
        },
        loadingCategories(state, payload) {
            state.loadingCategories = payload;
        },
        projectsToSubscribe(state, payload) {
            state.projectsToSubscribe = payload;
        },
        status(state, payload) {
            state.status = payload;
        }
    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                category: payload.categoryId,
                limit: payload.limit,
                cursor: payload.cursor,
            };

            const obj = {
                items: null,
            }

            if (variables.category) {
                const res = await PALOPTL().post('/', {
                    query: project.project.all,
                    variables,
                });
                const queryRes = res.data.data.projects;
                obj.items = queryRes.edges.map(toProject);
                commit('hasMore', queryRes.pageInfo.hasNextPage);
            }
            
            commit('loading', false);
            return obj;
        },
        async list ({ commit, dispatch }, payload)  {
            commit('projects', []);
            const projects = await dispatch('load', payload);
            commit('projects', projects.items);
        },
        //TODO
        async listAuthorsProjects(/*{ _ }, payload*/) {
           /* const res = await PALOPTL().post('/', {
                query: project.project.projects,
                variables: { ...payload }
            });
            const queryRes = res.data.data.projects;*/
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            const projects = await dispatch('load', { ...payload, cursor: getters.lastCursor});
            commit('appendProjects', projects.items);
        },

        async loadCategories({ commit }, payload) {
            commit('loadingCategories', true);
            const variables = {
                category: payload.categoryId,
            };
            const res = await PALOPTL().post('/', {
                query: project.category.subcategory.all,
                variables,
            });
            const queryRes = res.data.data.parent;
            const obj = {
                category: toPost(queryRes),
                subcategories: queryRes.categories.edges.map(toSubcategories),
            }
            
            commit('loadingCategories', false);
            return obj;
        },
        async listCategories ({ commit, dispatch }, payload)  {
            commit('subcategories', []);
            commit('category', null);
            const res = await dispatch('loadCategories', payload);
            commit('category', res.category);
            commit('subcategories', res.subcategories);
        },
        async detail({ commit }, payload) {
            commit('loading', true);
            commit('project', {});
            const variables = {
                id: payload.id,
            };
            const res = await PALOPTL().post('/', {
                query: project.project.one,
                variables,
            });
            const queryRes = res.data.data.project;
            commit('loading', false);
            commit('project', toDetailedProject(queryRes));
        },

        async projectsToSubscribe({ commit }, payload) {
            const res = await PALOPTL().post('/', {
                query: project.project.projectsToSubscribe,
                variables: { ...payload }
            });
            const queryRes = res.data.data.projectsToSubscribe;
            let data = toProjectsToSubscribe(queryRes);
            commit('projectsToSubscribe', data);
        },

        async subscribeFestival({ commit }, payload) {
            try {
                /*alert(JSON.stringify({
                    query: project.project.subscribeFestival,
                    variables: {
                        input: { ...payload }
                    }
                }))*/
                const res = await PALOPTL().post('/', {
                    query: project.project.subscribeFestival,
                    variables: {
                        input: { ...payload }
                    }
                });
                const queryRes = res.data.data.SubscribeFestival;
                commit('status', queryRes.status);
            } catch (error) {
                commit('status', {
                    success: false,
                    error: "Erro de processamento. Por favor tente mais tarde."
                })
            }
        }
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.projects)) {
                return null;
            }
            const len = state.projects.length;
            return state.projects[len -1].$cursor;
        }
    }
}

function toPost(node) {
    return {
        id: node.id,
        title: node.name,
        src: '/assets/img/plholder.jpg',
    };
} 

function toSubcategories(edge) {
    const node = edge.node;
    const categories = toPost(node);
    categories.categories = node.categories.edges.map(e => e.node).map(toPost).map(n => {
        n.$route = {query: {tipo: n.id}}; 
        return n;
    });
    return categories;
} 

function toProject(edge) {
    const node = edge.node;
    return {
        id: node.id,
        title: node.name,
        description: node.description,
        src: '/assets/img/plholder.jpg', //node.coverPic,
        $cursor: edge.cursor,
        $route: {path: `${PROJECT}/${node.id}`}
    };
} 

function toDetailedProject(node) {
    if (!node) {
        return null;
    }
    return {
        id: node.id,
        title: node.name,
        description: node.description,
        body: node.sinopse,
        externalLink: node.externalLink,
        src: '/assets/img/plholder.jpg', // node.coverPic,
        awards: [
            {
                src: '/assets/img/plaward.png'
            },
            {
                src: '/assets/img/plaward.png'
            },
            {
                src: '/assets/img/plaward.png'
            }
        ]
    };
}

function toProjectsToSubscribe(node) {
    if (!node)
        return [];
    return node.map(n =>{
        return {
            id: n.id,
            name: n.name,
            category: n.category.name + `${n.category.subcategory.category.name}:${n.category.subcategory.name}`
        };
    });
}