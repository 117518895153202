export const list = `query($limit: Int!, $profile: ID! $cursor: String) {
    festivals(first: $limit, creator: $profile, after: $cursor) {
        pageInfo {
            hasNextPage
        }
        edges {
            node { 
                id
                name
                avatar
                registerDateLimit
                dataPublished
                views
                category {
                  id
                  name
                }
                status
            }
            cursor
        }  
    }
}`;

export const detail = `query ($id: ID!, $withoutSubscriptions: Boolean = false) {
  festival(id: $id) {
    id 
    name
    avatar
    cartaz
    description
    views
    registerDateLimit
    startingDate
    location
    dataPublished
    criterias: festivalcriteriasSet {
      edges { node { criteria id status } }
    }
    judges: festivaljudgesSet {
      edges { node { category user{ id firstName lastName email } status } }
    }
    category {
      id
      name
    }
    allowedProjects {
      edges {
        node {
          id
          name
          status
        }
      }
    }
  
    subscriptions: subscriptionsSet @skip(if: $withoutSubscriptions) {
      edges {
        node {
          id
          project {
            id
            name
            description
            bio
            sinopse
            externalLink
            
            credits: projectcreditSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
            
            awards: projectawardSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
            
            medias: projectmediaSet {
              edges {
                node {
                  id
                  coverPic
                }
              }
            }
          }
          status
          requestDate
          validationDate
        }
      }
    }
    status
    
  }
}`;

export const create = `mutation ($input:  CreateFestivalInput!) {
    createFestival(input: $input) {
      festival {
        id
      }
      status {
        success error
      }
    }
}`;

export const update = `mutation ($input:  UpdateFestivalInput!) {
    updateFestival(input: $input) {
      festival {
        id
      }
      status {
        success error
      }
    }
}`;

export const judges = `query judges($isActive: Boolean!){
  judges:authors(isActive: $isActive) {
    edges {
      node {
        firstName lastName email user: userPtr { id }
      }
    }
  }
}`;

export const del = `mutation ($input:  DeleteFestivalInput!) {
    deleteFestival(input: $input) {
      status {
        success error
      }
    }
}`;