<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="400px" @click:outside="emitClose">
      <v-card class="pa-0 ma-0">
        <v-card-text class="pa-0">
          <Login ref="form" @close="emitClose" @loginSuccess="emitSuccess" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import Login from './Login';
export default {
    props: {
      dialog: Boolean,
    },
    components: {
        Login,
    },
    methods: {
      emitClose() {
        this.$refs.form.clean();
        this.$emit('close');
      },
      emitSuccess() {
        this.$refs.form.clean();
        this.$emit('success');
      }
    }
}
</script>