import is from 'plyr/src/js/utils/is';
import { getProviderByUrl, providers } from 'plyr/src/js/config/types.js';

import {FILE_UPLOADER, STORAGE_UPLOAD } from './api'

const SESSION_ID = "_yjgkb_";

export function isUrl(url) {
    return is.url(url);
}

export  function hasValidProvider(url) {
    const provider = getProviderByUrl(url);
    return provider === providers.vimeo || provider === providers.youtube
}



export function getYoutubeVimeoExternalLinkValue(url) {
    if (hasValidProvider(url)) {
        return url;
    }

    return null;
}


export function getEmptyOrUsefulName(name) {
    if (name == null) {
        return '';
    }

    return name === 'NA' ? '' : name;
}

export const CKEditorDefaultConfig = {
    toolbar: [ 
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        //'imageUpload',
        'blockQuote',
        'undo',
        'redo',
        //'insertTable'
    ],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
        ]
    }
};




const storage = {
    REQUEST_PROFILE_FESTIVAL_TYPE: 'REQUESTFESTIVALPROFILE',
    TYPE: {
        festival: {
            festival: {
                //{app}.{model}.{field}
                avatar: 'festival.festival.avatar',
                cartaz: 'festival.festival.cartaz'
            }
        },
    },
    async upload(
        file, elementId, type, 
        { fileInputName, elementInputName, typeStorageInputName }
    ) {
        if (file) {
            let fd = new FormData()
            fd.append(elementInputName, elementId)
            fd.append(fileInputName, file)
            fd.append(typeStorageInputName, type)
    
    
            const res = await FILE_UPLOADER(
                {
                    "Content-Type": "multipart/form-data"
                }
            ).post(STORAGE_UPLOAD, fd);
    
            if (!res.data.data) {
                throw Error('Error processing file');
            }

            return true;
        }

        return false;
    }
}

const PAGE_KEYS = {
    landingPage: 'landing-page',
    homePage: 'home-page',
    login: 'login',
    signUp: 'sign-up',
    quemSomos: 'quem-somos',
    projeto: 'projeto',
    contacto: 'contacto',
    instituicoesParceiras: 'instituicoes-parceiras',
    termoDeUso: 'termo-de-uso',
}

export {
    SESSION_ID,
    storage,
    PAGE_KEYS
}