import { PALOPTL, FILE_URL } from '../../../utils/api';
import { author } from '../../../gql';
import { AUTHOR } from '../../../routes';
import { getEmptyOrUsefulName, isUrl, getYoutubeVimeoExternalLinkValue } from '../../../utils';

import database from './database';
import dashboard from './dashboard';


import _ from 'lodash';
import moment from 'moment';

export default {
    strict: true,
    namespaced: true,
    modules: {
        database,
        dashboard,
     },
    state: {
        loading: false,
        hasMore: false,
        authors: [],
        author: null,
    },
    mutations: {
        authors(state, payload) {
            state.authors = payload || [];
        },
        author(state, payload) {
            state.author = payload;
        },
        appendAuthors(state, payload) {
            state.authors = _.concat(state.authors, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        }
    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
            };
            const res = await PALOPTL().post('/', {
                query: author.all,
                variables,
            });
            const queryRes = res.data.data.authors;
            const all = queryRes.edges.map(toPost);
            
            commit('loading', false);
            commit('hasMore', queryRes.pageInfo.hasNextPage);

            return all;
        },
        async list ({ commit, dispatch }, payload)  {
            const authors = await dispatch('load', payload);
            commit('authors', authors);
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            const authors = await dispatch('load', { ...payload, cursor: getters.lastCursor});
            commit('appendAuthors', authors);
        },
        async detail({ commit }, payload) {
            commit('loading', true);
            commit('author', null);

            const variables = {
                username: payload.username,
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: author.one,
                    variables,
                });
                const queryRes = res.data.data.author;
                
                if (queryRes.edges.length > 1) {
                    queryRes.edges.length = 1;
                }
                const one = queryRes.edges.length ? toAuthor(queryRes.edges[0]) : null;
                
                commit('loading', false);
                commit('author', one);
            } catch (error) {
                commit('loading', false);
            }
        },

        async detailById({ commit }, payload) {
            commit('loading', true);
            commit('author', null);

            const variables = {
                id: payload.id,
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: author.oneById,
                    variables,
                });
                const queryRes = res.data.data.profile;
                
                const one = toAuthor({ node: queryRes});
                console.log(one)
                
                commit('loading', false);
                commit('author', one);
            } catch (error) {
                commit('loading', false);
            }
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.authors)) {
                return null;
            }
            const len = state.authors.length;
            return state.authors[len -1].$cursor;
        }
    }
}


function toPost(edge) {
    const node = edge.node;
    const photos = node.profilephotos || {};
    return {
        id: node.id,
        title: `${node.firstName} ${getEmptyOrUsefulName(node.lastName)}`,
        avatar: photos.avatar,
        $cursor: edge.cursor,
        $route: { path: `${AUTHOR}/${node.username}`,}
    };
} 

function toAuthor(edge) {
    if (edge == null) {
        return null;
    }

    const node = edge.node;
    if (node == null) {
        return null;
    }
    const contact = node.contact || {};
    const photos = node.profilephotos || {};
    const portfolio = getPortfolio(node.portfolios.edges, photos.cover);
    //const projects = _.map(node.projects.edges, toProject);
    const projects = (node.projects.edges || []).filter(e => (e.node || {}).status).map(toProject);
    
    return {
        id: node.id,
        avatar: photos.avatar ? FILE_URL+"/"+photos.avatar : null,
        username: node.username,
        firstName: node.firstName,
        lastName: getEmptyOrUsefulName(node.lastName),
        projects,
        portfolio,
        get fullName() {
            return `${this.firstName} ${this.lastName}`;
        },
        contacts: [
            buildContact('Telefone', maskContact(contact.phoneNumber)),
            buildContact('Email', node.email),
            buildContact('Website', contact.website),
            buildContact('Facebook', contact.facebookPage),
            buildContact('Instagram', contact.instagramUsername),
            buildContact('Youtube', contact.youtubeLink),
        ],
        
    }
}

function buildContact(name, value) {
    return {
        name,
        value
    };
}

function maskContact(ct) {
    if(!ct) return ct;

    let nct = '' + ct;
    nct = nct.trim();
    let min = 3;
    let beginWithPlus = false;

    if (nct.startsWith('+')) {
        min = 4;
        beginWithPlus = true;
    }

    if (nct.length > min) {
        nct = nct.replaceAll('-', '').replaceAll(' ', '');
        nct = nct.substring(0, min) + ' ' + nct.substring(min);
    } 
    
    return ( (beginWithPlus ? '' : '+') + nct);
}


function getPortfolio(edges, coverPhoto) {
    if (!edges || edges.length == 0) {
        return {};
    }
    const node = edges[0].node;
    return {
        cover: coverPhoto ? FILE_URL+"/"+coverPhoto : null ,
        rel: isUrl(node.externalLink) ? node.externalLink : null ,
        quote: node.mentionedIn,
        bio: node.personalBio,
        cinematographicBio: node.professionalBio
    };
}

function toProject(edge) {
    let node = edge && edge.node ? edge.node : {};

    let media = null;
    if (node.medias && node.medias.edges)
        media = node.medias.edges[0].node.coverPic;

    return {
        id: node.id,
        title: node.name,
        //src: media ? FILE_URL+"/"+media : null,
        src: node.cover ? FILE_URL + "/" + node.cover : FILE_URL + "/" + media,
        bio: node.bio,
        externalLink: getYoutubeVimeoExternalLinkValue(node.externalLink),
        awards: _.map(node.awards.edges, toProjectExtra),
        credits: _.map(node.credits.edges, toProjectExtra),
        dataProducaoFmt: node.dataProducao ? moment(node.dataProducao, 'YYYY-MM-DD').format('DD.MM.YYYY'): null,
        paisProducaoDescricao: node.paisProducao ? node.paisProducao.name : null,
    };
} 

/*function toProjectMedia(edges) {
    if (!edges) {
        return null;
    }

    if (!edges[0]) {
        return null;
    }
    const node = edges[0].node;
    if (!node) {
        return null;
    }
    return node.coverPic;
}*/

function toProjectExtra(edge) {
    const node = edge && edge.node ? edge.node : {};
    return {
        id: node.id,
        name: node.name,
    };
}

