module.exports = {
    'profile.control-panel.home': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Controlo',
            disabled: true,
            href: '/control-panel',
        },
    ],
    'profile.control-panel.project.home': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Controlo',
            disabled: false,
            href: '/control-panel',
        },
        {
            text: 'Projetos',
            disabled: true,
            href: '/control-panel/projeto',
        },
    ],
    'profile.control-panel.project.new': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Controlo',
            disabled: false,
            href: '/control-panel',
        },
        
        {
            text: 'Projetos',
            disabled: false,
            href: '/control-panel/projeto',
        },
        {
            text: 'Novo',
            disabled: true,
            href: '/control-panel/projeto/novo',
        },
    ],
    'profile.control-panel.project.view': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Controlo',
            disabled: false,
            href: '/control-panel',
        },
        
        {
            text: 'Projetos',
            disabled: false,
            href: '/control-panel/projeto',
        },
        {
            text: 'Detalhes',
            disabled: true,
            href: '#',
        },
    ],
    'profile.control-panel.project.edit': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Controlo',
            disabled: false,
            href: '/control-panel',
        },
        
        {
            text: 'Projetos',
            disabled: false,
            href: '/control-panel/projeto',
        },
        {
            text: 'Editar',
            disabled: true,
            href: '#',
        },
    ],

    'profile.jury-panel.auxiliary.home': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Jurí',
            disabled: false,
            href: '/painel-juri',
        },

        {
            text: 'Auxiliar',
            disabled: true,
            href: '#',
        },
    ],

    'profile.jury-panel.jury.home': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Jurí',
            disabled: false,
            href: '/painel-juri',
        },

        {
            text: 'Jurí',
            disabled: true,
            href: '#',
        },
    ],


    'profile.jury-panel.main.home': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Jurí',
            disabled: false,
            href: '/painel-juri',
        },

        {
            text: 'Principal',
            disabled: true,
            href: '#',
        },
    ],

    'profile.jury-panel.festival.home': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Jurí',
            disabled: false,
            href: '/painel-juri',
        },

        {
            text: 'Festival',
            disabled: true,
            href: '#',
        },
    ],

    'profile.jury-panel.home.index': [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Painel Jurí',
            disabled: true,
            href: '#',
        },
    ],
}