import * as festivalRequestProfile from './festival-request-profile';
const dashboard = require('./dashboard');

const news = {
  all: `query($limit: Int!, $cursor: String) {
      allNews(first: $limit, after: $cursor) {
        pageInfo {
          hasNextPage
        },
        edges {
          node {
            id
            coverPic
            title
            sinopse
            dataPublished
          },
          cursor
        }
      }
  }`,
  one: `query($id: ID!) {
    oneNews(id: $id) {
          id
          coverPic
          title
          subTitle
          sinopse
          body
          dataPublished
    }
  }`,
  mutation: {
    view: `mutation ($input: ViewNewsInput!) {
      viewNews(input: $input) {
        clientMutationId
      }
    }`
  }
};

const festival = {
  category: {
    all: `query($limit: Int, $cursor: String) {
      festivalCategories(first: $limit, after: $cursor) {
        pageInfo {
          hasNextPage
        },
        
        edges {
          node {
            id
            name
          },
          cursor
        }
      }
    }`,
  },
  festival: {
    filter: `query($category: ID!, $limit: Int!, $profile: ID, $local: String, $name: String, $startingDate: Date, $allowedProj: [ID]) {
      festivals: festivalsList(category: $category, limit: $limit, name: $name, local: $local, date: $startingDate, allowedProj: $allowedProj){
        category{id name}
        id
        avatar
        startingDate
        cartaz
        name
        description
        registerDateLimit
        dataPublished
        isSubscriptionsOpen
        allowedProjects{edges {node {id name subcategory{name category{name}}}}}
        creator{ firstName lastName email id}
        addedToUserFavorite(profile: $profile)
      }  
    }`,
    all: `query($id: ID!, $limit: Int!, $cursor: String, $name: String, $startingDate: Date) {
      category: festivalCategory(id: $id) {
        id name 
        festivals: festivalSet(first: $limit, after: $cursor, name_Icontains: $name, startingDate: $startingDate) {
          pageInfo {
            hasNextPage
          }
          
          edges {
            node {
              id
              avatar
              startingDate
              cartaz
              name
              description
              registerDateLimit
              dataPublished
            }
            cursor
          }
        }
      }
    }`,
    one: `query ($id: ID!, $profile: ID) {
      festival(id: $id) {
        id name avatar registerDateLimit dataPublished isSubscriptionsOpen maxSubsReached maxSubsAllowed cartaz
        allowedProjects{edges {node {id name subcategory{name category{name}}}}}
        description creator{ firstName lastName email id}
        addedToUserFavorite(profile: $profile)
      }
    }`,
    isMyFestival: `query isMyFestival($profile: ID!, $festival: ID!) {
      isMyFestival(profile: $profile, festival: $festival) {
        isMyFestival
      }
    }`,

    subscriptions: `query subscriptions($profile: ID!) {
      subscriptions(project_Owner: $profile) {
        edges {
          node {
            id comment festival{name id avatar category{name} description startingDate trackn location
            creator{firstName lastName email}} requestDate status validationDate
            project {id name category{name subcategory{name category{name}}}}
          }
        }
      }
    }`,

    subscriptionsByFestival : `query subscriptions($festival: ID!) {
      subscriptions(festival: $festival) {
        edges {
          node {
            id comment festival{name id avatar category{name} location description startingDate trackn
            creator{firstName lastName email}} requestDate status validationDate
            project {
              id name category{name subcategory{name category{name}}}
              bio sinopse createdAt credits: projectcreditSet{edges{node{name}}}
              awards: projectawardSet{edges{node{name}}} media: projectmediaSet{edges{node{coverPic}}}
              externalLink author: owner{ id firstName lastName email }
            }
          }
        }
      }
    }`,

    lastSubscriptions: `query subscriptions($profile: ID!, $last: Int!) {
      lastSubscriptions: subscriptions(project_Owner: $profile, last: $last) {
        edges {
          node {
            festival{name} requestDate project {id}
          }
        }
      }
    }`,

    countSubscriptions: `query count($profile: ID!){
      subscriptionsCount(profile: $profile) {
        total accepted pendding
      }
    }`,

    favorites: `query favorites($profile: ID!, $first: Int!){
      favorites(first: $first, profile: $profile){
        edges {
          node{
            festival{name id}
          }
        }
      }
    }`,

    manageFavorites: `mutation manage($input: ManageFavoriteInput!) {
      manageFavorites(input: $input) {
        status{ error success }
      }
    }`
  },
}


const project = {
  category: {
    all: `query($limit: Int!, $cursor: String) {
      projectCategories(first: $limit, after: $cursor) {
        pageInfo {
          hasNextPage
        },
        
        edges {
          node {
            id
            name
          },
          cursor
        }
      }
    }`,
    subcategory: {
      all: `query ($category: ID!) {
        parent: projectCategory(id: $category) {
          id name 
          categories: subcategorySet {
            edges {
              node {
                id name
                categories: subcategoryofsubcategorySet {
                  edges {
                    node {
                      id name
                    }
                  }
                }
              }
            }
          }
        }
      }`
    }
  },
  subSubCategory: {
    all: `query {
      projectSubSubCategories: projectSubcategoriesofsubcategory {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            name
            status
            subcategory {
              id
              name
              category {
                id
                name
              }
            }
          }
        }
      }
    }
    `
  },
  project: {
    all: `query ($category: ID!, $limit: Int!, $cursor: String) {
      projects (first: $limit, category: $category, after: $cursor) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            name
            
            description
          }
          cursor
        }
      }
    }`,
    one: `query ($id: ID!) {
      project: project(id: $id) {
        id name   description sinopse
        description externalLink
      }
    }`,

    projectsToSubscribe : `query projects($author: ID!, $festival_: ID!, $categories: [ID]!){
      projectsToSubscribe(owner: $author, categories: $categories, festival_: $festival_) {
        name id category{name subcategory{name category{name}}}
      }
    }`,

    subscribeFestival: `mutation subscribe($input: SubscribeInput!) {
      SubscribeFestival(input: $input) {
        status{success error}
      }
    }`,

    projects: `query projects($owner: ID!) {
      projects(owner: $owner) {
        edges {
          node {
            id cover name sinopse category { name }
            credits:projectcreditSet { edges { node { name } } }
          }
        }
      }
    }`
  }
}

const author = {
  database: {
    filters: `query {
      profileCategories(orderBy: ["name"]) {
        edges {
          node {
            id
            name
            status
            subcategories(orderBy: ["name"]) {
              edges {
                node {
                  id
                  name
                  status
                }
              }
            }
          }
        }
      }
      
      cities (orderBy:["name"]) {
        edges {
          node {
            id name status 
            country {
              id name status
            }
          }
        }
      }
    }`,
    all: `query ($limit: Int!, $cursor: String, $categories: [[String]], $address: ID, $country: ID, $q: String) {
      authors: authorsDatabase(first: $limit, after: $cursor, categories: $categories, address: $address,  country: $country, query: $q, orderBy: ["first_name", "last_name"]) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            username
            firstName
            lastName
            isActive
            profilephotos {
              avatar
            }
            subcategories(first: 2) {
              edges {
                node {
                  id
                  name
                  status
                }
              }
            }
          }
          cursor
        }
      }
    }`
  },
  all: `query ($limit: Int!, $cursor: String) {
    authors(first: $limit, after:$cursor) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id username firstName lastName
          avatar
        }
        cursor
      }
    }
  }`,
  one: `query ($username: String!) {
    author: authors(first: 1, username: $username) {
      edges {
        node {
          id
          username
          firstName
          lastName
          email
          
          profilephotos {
            avatar cover
          }
          
          contact {
            website
            facebookPage
            instagramUsername
            phoneNumber
            youtubeLink
          }
          portfolios: portfolioSet {
            edges {
              node {
                mentionedIn
                externalLink
                personalBio
                professionalBio
              }
            }
          }
          projects: projectSet {
            edges {
              node {
                id
                name
                cover
                bio
                externalLink
                status
                dataProducao
                paisProducao {
                  id name
                }
                medias: projectmediaSet(first: 1) {
                  edges {
                    node {
                      id
                      coverPic
                    }
                  }
                }
                credits: projectcreditSet {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                awards: projectawardSet {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`,
  oneById:`query ($id: ID!) {
    profile: author(id: $id) {
      id
      username
      firstName
      lastName
      email
      profilephotos {
        cover
        avatar
      }
      contact {
        website
        facebookPage
        instagramUsername
        phoneNumber
        youtubeLink
      }
      portfolios: portfolioSet {
        edges {
          node {
            mentionedIn
            externalLink
            personalBio
            professionalBio
          }
        }
      }
      projects: projectSet {
        edges {
          node {
            id
            name
            bio
            cover
            externalLink
            medias: projectmediaSet(first: 1) {
              edges {
                node {
                  id
                  coverPic
                }
              }
            }
            credits: projectcreditSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
            awards: projectawardSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }`
}

const auth = {
  signIn: `mutation ($input: SignInInput!) {
    signIn(input: $input) {
      profile {
        id
        username
        firstName
        lastName
        isFestivalProfile

        profilephotos{
          avatar
        }

        uid: userPtr {
          id
        }
      }
      status {
        success
        error
      }
    }
  }`,
  signUp: `mutation ($input: SignUpInput!) {
    signup(input: $input) {
      profile {
        id
      }
      status {
        success error
      }
    }
  }`,
  confirmSignUp: `mutation ($input: ConfirmSignUpInput!) {
    confirmSignup(input: $input) {
      status {
        success error
      }
    }
  }`,
  resetProfilePassword: `mutation ($input: ResetProfilePasswordInput!) {
    resetProfilePassword(input: $input) {
      status {
        success error
      }
    }
  }`,
  confirmResetProfilePassword:  `mutation ($input: ConfirmResetProfilePasswordInput!) {
    confirmResetProfilePassword(input: $input) {
      status {
        success error
      }
    }
  }`,
  user: {
    one: `query ($id: ID!) {
      author(id: $id) {
        id
        username
        firstName
        lastName
        email
        isHistorical
        profilephotos {
          avatar cover
        }
        contact {
          website
          facebookPage
          instagramUsername
          phoneNumber
          youtubeLink
        }
        subcategories {
          edges {
            node {
              id
              status
            }
          }
        }
        address {
          id
        }
        portfolios: portfolioSet {
          edges {
            node {
              mentionedIn
              externalLink
              personalBio
              professionalBio
            }
          }
        }
        projects: projectSet {
          edges {
            node {
              id
              name
              bio
              externalLink
              description
              sinopse
              status
              category {
                id
              }
              credits: projectcreditSet {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              awards: projectawardSet {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    `,
    projectCategories: `query {
      projectCategories {
        edges {
          node {
            id name
            subcategories: subcategorySet {
              edges {
                node {
                  id name 
                  subsubcategories: subcategoryofsubcategorySet {
                    edges {
                      node {
                        id name status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    mutation: {
      project: {
        create: `mutation($input: NewProjectInput!) {
          createProject(input: $input) {
            project {
              id 
            }
            status {
              success error
            }
          }
        }`,
        update: `mutation($input: UpdateProjectInput!) {
          updateProject(input: $input) {
            project {
              id 
            }
            status {
              success error
            }
          }
        }`,
        delete: `mutation($input: DeleteProjectInput!) {
          deleteProject(input: $input) {
            status {
              success error
            }
          }
        }`
      },
      profile: {
        contact: {
          update: `mutation($input: UpdateProfileContactInput!) {
            updateProfileContact(input: $input) {
              status {
                success error
              }
            }
          }`
        },
        data: {
          update: `mutation ($input: UpdateProfileDataInput!) {
            updateProfile(input: $input) {
              status {
                success
                error
              }
              profile {
                id
              }
            }
          }`
        }
      }
    },
    projects: {
      list: `query ($id: ID!, $limit: Int!, $cursor: String) {
        author(id: $id) {
          projects: projectSet(last: $limit, after: $cursor) {
            edges {
              node {
                id
                cover
                name
                sinopse
                status
                dataProducao
                paisProducao {
                  id
                  name
                }
                medias: projectmediaSet(first: 1) {
                  edges {
                    node {
                      id
                      coverPic
                    }
                  }
                }
                credits: projectcreditSet {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              },
              cursor
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }      
      `,
      one:`query ($id: ID!) {
        project(id: $id) {
          id
          cover
          banner
          name
          bio
          externalLink
          description
          sinopse
          status
          dataProducao
          paisProducao {
            id
            name
          }
          medias: projectmediaSet(first: 1) {
            edges {
              node {
                id
                coverPic
              }
            }
          }
          category {
            id
            name
          }
          credits: projectcreditSet {
            edges {
              node {
                id
                name
              }
            }
          }
          awards: projectawardSet {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
      `
    }
  }
}

const about = {
  one: `query($code: String!) {
    oneAbout(code: $code) {
      code title src:banner
      body
    }
  }`
}


const page = {
  one: `query($pageKey: String!) {
    onePage: page(pageKey: $pageKey) {
      banner {
        id
        src
      }
      text {
        id
        title
        txt
      }
    }
  }`
}

export {
  news,
  festival,
  project,
  author,
  auth,
  about,
  festivalRequestProfile,
  dashboard,
  page,
};