import { PALOPTL, FILE_URL } from '../../../utils/api';
import { getEmptyOrUsefulName } from '../../../utils';


import { author } from '../../../gql';
import { AUTHOR } from '../../../routes';

import _ from 'lodash';

export default {
    strict: true,
    namespaced: true,
    state: {
        loading: false,
        loadingFilter: false,
        hasMore: false,
        filters: {
            categories: [],
            cities: [],
            countries: [],
        },
        authors: [],
        author: null,
    },
    mutations: {
        authors(state, payload) {
            state.authors = payload || [];
        },

        filters(state, payload) {
            state.filters = payload || {
                categories: [],
                cities: [],
            };
        },
        appendAuthors(state, payload) {
            state.authors = _.concat(state.authors, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        loadingFilter(state, payload) {
            state.loadingFilter = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        }
    },
    actions: {
        async load({ commit }, payload) {
            commit('loading', true);
            
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
                address: payload.address,
                country: payload.country,
                categories: payload.categories,
                q: payload.query,
            };

            const res = await PALOPTL().post('/', {
                query: author.database.all,
                variables,
            });
            const queryRes = res.data.data.authors;
            const all = queryRes.edges.map(toPost);
            
            commit('loading', false);
            commit('hasMore', queryRes.pageInfo.hasNextPage);

            return all;
        },
        async list ({ commit, dispatch }, payload)  {
            try {
                const authors = await dispatch('load', payload);
                commit('authors', authors);
            } catch (err) {
                commit('loading', false);
            }
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            try {
                const authors = await dispatch('load', { ...payload, cursor: getters.lastCursor});
                commit('appendAuthors', authors);
            } catch(err) {
                commit('loading', false);
            }
        },

        async loadFilters({ commit }) {
            commit('loadingFilter', true);
            commit('filters', null);

            const res = await PALOPTL().post('/', {
                query: author.database.filters,
            });
            const queryRes = res.data.data.profileCategories;
            const citiesRes = res.data.data.cities;

            commit('loadingFilter', false);
            commit('filters', {
                categories: queryRes ? queryRes.edges
                    .filter(e => e.node)
                    .filter(e => e.node.status)
                    .map(toCategoryFilter) : [],
                cities:  citiesRes ? citiesRes.edges
                    .filter(e => e.node.status)
                    .map(toAddressFilter) : [],
                countries:  citiesRes ? distinctCountry(citiesRes.edges
                    .filter(e => e.node.status)
                    .map(toCountry)) : [],
            });
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.authors)) {
                return null;
            }
            const len = state.authors.length;
            return state.authors[len -1].$cursor;
        }
    }
}

function getCategories(cats) {
    if (!cats || cats.length == 0) {
        return '';
    }
    const list = cats.filter(c => c.node).map(c => c.node).map(n => n.name);
    if (list.length == 0) {
        return '';
    }
    return list[0] + (list.length > 1 ? ', ' + list[1] : '');
}

function toPost(edge) {
    const node = edge.node;
    const photos = node.profilephotos ? node.profilephotos : {};
    return {
        id: node.id,
        title: `${node.firstName} ${getEmptyOrUsefulName(node.lastName)}`,
        subtext: getCategories(node.subcategories ? node.subcategories.edges : []),
        avatar: photos.avatar ? FILE_URL+"/"+photos.avatar : null,
        $cursor: edge.cursor,
        $route: { path: `${AUTHOR}/${node.username}`,}
    };
}

function toAddressFilter(edge) {
    const node = edge.node;
    return {
        value: node.id,
        text: node.name,
        subtext: node.country.name
    };
}

function toCountry(edge) {
    const node = edge.node;
    return {
        value: node.country.id,
        text: node.country.name,
        subtext: node.country.name
    };
}

function distinctCountry(countries) {
    const v = [];
    
    countries.forEach(o => {
        const exists = v.findIndex((e ) => {
            return e.value === o.value
        }) != -1;

        if (!exists) {
            v.push(o);
        }
    });

    v.sort((p, s) => {
        if (p.text > s.text) return 1;
        if (p.text < s.text) return -1;
        return 0;
    })

    return v;
}

function toFilter(node) {
    return {
        id: node.id,
        title: node.name,
        selections: [],
    };
}

function toCategoryFilter(edge) {
    const node = edge.node;
    const category = toFilter(node);
    category.categories = node.subcategories.edges
        .filter(e => e.node)
        .filter(e => e.node.status).map(e => e.node).map(toFilter);
    return category;
} 