<template>
    <div
        class="mx-auto post"
        outlined>
        <div class="post-body" :class="clipped ? clipclass : ''">
            <v-list-item class="ma-0 pa-0 pl-4 post-header cliped-rb-path" v-show="!headless" style="height: 24px; max-height: 24px">
                <v-list-item-content class="ma-0 pa-0" style="height: 24px; max-height: 24px; ">
                    <v-list-item-title class="ma-0 pa-0 h6 text-primary text-uppercase">{{ title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <div class="slot">
                <slot></slot>
                <v-row class="ma-0 pa-0" justify="start" v-if="clipped" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col style="max-width: 24px;">
                        <v-img 
                            height="24" width="24"  max-height="24" max-width="24" 
                            src="/icon.png" 
                            style="opacity: 0.2;"/>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        headless: {
            type: Boolean,
            default: false,
        },
        clipped: {
            type: Boolean,
            default: false
        },
        clipclass: {
            type: String,
            default: 'cliped-rb-path',
        }
    }
}
</script>