// import {PALOPTL, GQL } from '../../../utils/api';


export default {
    strict: true,
    namespaced: true,
    states: {
        projects: []
    },

    mutations: {
        projects(state, payload) {
            state.projects = payload;
        }
    },

    actions: {
        //async getProjects({ commit }, payload) {  }
    }
}