<template>
<div class="mx-auto overflow-hidden menu-header">
    <v-app-bar
      app
      clipped-right
      color="primary"
      class="elevation-0 text-secondary"
      style="margin: 0 4px; border-radius: 4px"
      flat
      dark>
      
      <v-row no-gutters align="center" justify="center">
          <v-app-bar-nav-icon class="drawer-btn"
            @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <div class="align-center pl-8 pt-2 mr-10 pr-4">
            <a href="/" class="d-none d-md-flex"><v-img
              alt="PALOP TL"
              class="shrink mr-2"
              contain
              src="/assets/img/Rede_Top.svg" width="200" height="28"/></a>

            <a href="/" class="d-flex d-sm-flex d-md-none"><v-img
              alt="PALOP TL"
              class="shrink mr-2"
              contain
              height="35" width="100"
              src="/assets/img/logo-sm.svg"/></a>

          </div>
              <Menu v-for="(item, index) in menus"
              :key="index" :item="item"/>
          <span class="user-area">
            <UserSection :user="user"
              @openSignUp="signUpDialog=true" 
              @openLogin="loginDialog = true" /></span>
      </v-row>

    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark class="card-secondary"
      temporary>
      <v-list
        nav
        dense  v-if="menus">
        <MenuDrawer v-for="(item, index) in menus"
              :key="index" :item="item"/>

        <v-divider style="border-color: #FFCC0080; border-width: 1px;"></v-divider>
          <div class="pa-2">
            <UserSection :user="user"
              @openLogin="loginDialog = true" 
              @openSignUp="signUpDialog=true" drawer/>
          </div>
      </v-list>
    </v-navigation-drawer>
    <LoginDialog 
      :dialog="loginDialog" 
      @close="loginDialog = false" 
      @success="onSuccess"/>
    
    <SignUpDialog :dialog="signUpDialog" @close="signUpDialog = false" />
</div>
</template>
<script>
import Menu from './Menu';
import MenuDrawer from './MenuDrawer';
import LoginDialog from './LoginDialog';
import SignUpDialog from './SignUpDialog';

import UserSection from './auth/UserSection';
export default {
    props: ['menus',],
    data: () => ({
        drawer: false,
        group: null,
        loginDialog: false,
        signUpDialog: false,
        user: null,
    }),
    watch: {
      group () {
        this.drawer = false
      },
      '$route': function() {
            this.refreshUser();
        }
    },
    components: {
        Menu,
        MenuDrawer,
        UserSection,
        LoginDialog,
        SignUpDialog,
    },
    mounted() {
      this.refreshUser();
    },
    methods: {
      onSuccess() {
        this.loginDialog = false;
        this.refreshUser();
      },
      refreshUser() {
        if (this.$user.isAuthenticated) {
            return this.user = this.$user.info;
        }
        this.user = null;
      }
    }
}
</script>