// import {PALOPTL, GQL_API, FILE_URL } from '../../../utils/api';
//import { JURY_PANEL } from '../../../routes';
// import { dashboard } from '../../../gql';
import _ from 'lodash';


// Festival Profile Request
export default {
    strict: true,
    namespaced: true,
    state: {
        items: [],
        loading: false,
        item:null,
        processing: false,
        hasMore: false
    },
    mutations: {
        items(state, payload) {
            state.items = payload || [];
        },
        item(state, payload) {
            state.item = payload;
        },
        appendItems(state, payload) {
            state.items = _.concat(state.items, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        processing(state, payload) {
            state.processing = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
    },

    actions: {

        async detail({ commit }/*, payload*/) {
            commit('loading', true);
            commit('item', null);

            /*const variables = {
                id: payload.id
            };
            const res = await PALOPTL().post(GQL_API, {
                query: festivalRequestProfile.detail,
                variables,
            });
            const queryRes = res.data.data.request;
            */
            
            const one = toItem({node: null /*queryRes*/});
            
            commit('loading', false);
            commit('item', one);
        },

        async load({ commit }/*, payload*/) {
            commit('loading', true);
            /*const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
            };

            const res = await PALOPTL().post(GQL_API, {
                query: festivalRequestProfile.list,
                variables,
            });*/
            //const queryRes = res.data.data.requests;
            const all = mockData();//queryRes.edges.map(toItem);
            
            commit('loading', false);
            commit('hasMore', false /*queryRes.pageInfo.hasNextPage*/);

            return all;
        },
        async list ({ commit, dispatch }, payload)  {
            try {
                const items = await dispatch('load', payload);
                commit('items', items);
            } catch (err) {
                commit('loading', false);
            }
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            try {
                const items = await dispatch('load', { ...payload, cursor: getters.lastCursor});
                commit('appendItems', items);
            } catch(err) {
                commit('loading', false);
            }
        },

        async submitReview({ commit }, payload) {
            const data = payload.data;
            const update = !!data.id; 
            //const token = payload.token
            commit('processing', true);

            try {
                /*const input = {
                    profile: token.id,
                };

                let query;
                if (update) {
                    query = null; // avante
                } else {
                    query = festivalRequestProfile.create;
                }
                
                const res = query ? await PALOPTL().post(GQL_API, {
                    query: query,
                    variables: { input }
                }) : { 
                    request: { id: payload.data.id }, 
                    status: { message: 'Sem Modificação', success: true} 
                };

                const queryRes = update ? 
                        res : 
                        res.data.data.createRequest;

                if (!queryRes.request) {
                    commit('processing', false);
                    return { 
                        isUpdate: update, 
                        success: false, 
                        messages: [{
                            success: false,
                            message:  queryRes.status.message,
                        }]
                    };
                }

                
                const id = queryRes.request.id;
                const messages = []
                

                commit('processing', false);

                return {
                    isUpdate: update,
                    success: queryRes.status.success && fileSuccess,
                    data: { ...data, id},
                    messages: [{
                        success: queryRes.status.success,
                        message: queryRes.status.message
                    }, ...messages],
                };*/
            } catch(err) {
                commit('processing', false);
                return {
                    success: false,
                    isUpdate: update,
                    messages: [{
                        success: false,
                        message: 'Problema no processamento do pedido.',
                    }]
                };
            }
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.requests)) {
                return null;
            }
            const len = state.requests.length;
            return state.requests[len -1].$cursor;
        }
    }
}


function toItem(edge) {
    const node = edge.node;
    if (!node) return null;
    
    return {
        project: {

        },
        //criteria, rating, etc
        status: null, //Avaliado ou não
        $cursor: edge.cursor,
        $route: { 
            detail: {path: `??????/${node.id}`,},
        }
    };
}


function mockData() {
    return [
        {
            project: {
                "id": "UHJvamVjdE5vZGU6MjU=",
                "title": "Meu novo projeto 3",
                "src": "http://127.0.0.1:8000/media/project_b6fc6690-df70-11ea-acab-d46a6ad99c87/BANNERS_HOMEPAGE_1.jpg",
                "$route": {
                    "view": "/control-panel/projeto/UHJvamVjdE5vZGU6MjU=",
                }
            },
            obs: null,
            generalEvaluation: null
        },
        {
            project: {
                "id": "UHJvamVjdE5vZGU6MjQ=",
                "title": "Meu Primeiro Projeto",
                "src": "http://127.0.0.1:8000/media/project_25672062-df70-11ea-acab-d46a6ad99c87/background.png",
                "$route": {
                    "view": "/control-panel/projeto/UHJvamVjdE5vZGU6MjQ=",
                }
            },
            obs: 'Lorem Ipsum dolor',
            generalEvaluation: 'Lorem Ipsum dolor',
        }
    ];
}