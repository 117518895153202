import Vuex from 'vuex';
import news from './modules/news';
import festival from './modules/fetival';
import project from './modules/project';
import author from './modules/author';
import auth from './modules/auth';
import dashboard from './modules/dashboard';
import mySpace from './modules/my-space';
import juryPanel from './modules/jury-panel';


import { PALOPTL, FILE_URL } from '../utils/api';
import { about, page } from '../gql';

export default new Vuex.Store({
    modules: {
        news,
        festival,
        project,
        author,
        auth,
        dashboard,
        mySpace,
        juryPanel,
    },
    state: {
        loadingAbout: false,
        abouts: {
            'quem-somos': null,
            'projeto': null,
            'contacto': null,
            'instituicoes-parceiras': null,
        },
    },
    mutations: {
        setAbout(state, payload) {
            state.abouts = Object.assign(state.abouts, payload);
        },
        loadingAbout(state, payload) {
            state.loadingAbout = payload;
        },

    },
    actions: {
        async loadAbout({ commit }, payload) {
            
            commit('loadingAbout', true);
            const variables = {
                code: payload.code,
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: about.one,
                    variables,
                });
                const oneRes = res.data.data.oneAbout;
                if (oneRes) {
                    oneRes.src  = oneRes.src ? FILE_URL+"/"+oneRes.src : null;
                }
                commit('loadingAbout', false);
                commit('setAbout', { [payload.code]: oneRes});
            } catch (err) {
                commit('loadingAbout', false);
            }
        },

        async loadPage(_, payload) {
            const variables = {
                pageKey: payload.key,
            };
            try {
                const res = await PALOPTL().post('/', {
                    query: page.one,
                    variables,
                });
                const oneRes = res.data.data.onePage;
                if (oneRes) {
                    if (oneRes.banner) {
                        oneRes.banner.src  = oneRes.banner.src ? FILE_URL+"/"+oneRes.banner.src : null;
                    }
                    
                } else {
                    return {}
                }

                return oneRes;
            } catch (err) {
                throw err;
            }
        },
    },
    getters: {
        aboutUs(state) {
            return state.abouts['quem-somos'];
        },
        projects(state) {
            return state.abouts['projeto'];
        },
        partners(state) {
            return state.abouts['instituicoes-parceiras'];
        },
        contact(state) {
            return state.abouts['contacto'];
        },
    }
});