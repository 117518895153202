import {PALOPTL, GQL_API, DOMAIN } from '../../../utils/api';
import { storage } from '../../../utils';
import { profileDashboard } from '../../../routes';
import { festivalRequestProfile } from '../../../gql';
import _ from 'lodash';
import { isFestivalProfile } from '../../../gql/festival-request-profile';


// Festival Profile Request
export default {
    strict: true,
    namespaced: true,
    state: {
        requests: [],
        loading: false,
        request:null,
        processingRequest: false,
        hasMore: false,
        isFestivalProfile: false
    },
    mutations: {
        requests(state, payload) {
            state.requests = payload || [];
        },
        request(state, payload) {
            state.request = payload;
        },
        appendRequests(state, payload) {
            state.requests = _.concat(state.requests, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        processingRequest(state, payload) {
            state.processingRequest = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
        isFestivalProfile(state, isFestivalProfile) {
            state.isFestivalProfile = isFestivalProfile;
        }
    },

    actions: {

        async detail({ commit }, payload) {
            commit('loading', true);
            commit('request', null);

            const variables = {
                id: payload.id
            };
            const res = await PALOPTL().post(GQL_API, {
                query: festivalRequestProfile.detail,
                variables,
            });
            const queryRes = res.data.data.request;
            
            
            const one = toRequest({node: queryRes});
            
            commit('loading', false);
            commit('request', one);
        },
        async load({ commit }, payload) {
            commit('loading', true);
            const variables = {
                limit: payload.limit,
                cursor: payload.cursor,
                profile: payload.profile
            };

            const res = await PALOPTL().post(GQL_API, {
                query: festivalRequestProfile.list,
                variables,
            });
            const queryRes = res.data.data.requests;
            const all = queryRes.edges.map(toRequest);
            
            commit('loading', false);
            commit('hasMore', queryRes.pageInfo.hasNextPage);

            return all;
        },
        async list ({ commit, dispatch }, payload)  {
            try {
                const requests = await dispatch('load', payload);
                commit('requests', requests);
            } catch (err) {
                commit('loading', false);
            }
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            try {
                const requests = await dispatch('load', { ...payload, cursor: getters.lastCursor});
                commit('appendRequests', requests);
            } catch(err) {
                commit('loading', false);
            }
        },

        async createOrUpdate({ commit }, payload) {
            const data = payload.data;
            const update = !!data.id; 
            const token = payload.token
            commit('processingRequest', true);

            try {
                const input = {
                    profile: token.id,
                };

                let query;
                if (update) {
                    query = null; // avante
                } else {
                    query = festivalRequestProfile.create;
                }
                
                const res = query ? await PALOPTL().post(GQL_API, {
                    query: query,
                    variables: { input }
                }) : { 
                    request: { id: payload.data.id }, 
                    status: { message: 'Sem Modificação', success: true} 
                };

                const queryRes = update ? 
                        res : 
                        res.data.data.createRequest;

                if (!queryRes.request) {
                    commit('processingRequest', false);
                    return { 
                        isUpdate: update, 
                        success: false, 
                        messages: [{
                            success: false,
                            message:  queryRes.status.message,
                        }]
                    };
                }

                
                const id = queryRes.request.id;
                const messages = []
                let fileSuccess = true;
                try {
                    const fileMessage = {
                        success: false,
                        message: 'Problema ao caregar ficheiro.',
                    }

                    const success = await storage.upload(
                        data.srcFile, 
                        id, 
                        storage.REQUEST_PROFILE_FESTIVAL_TYPE,
                        { 
                            fileInputName: 'file', elementInputName: 'element', 
                            typeStorageInputName: 'type'
                        }
                    );

                    if (success) {
                        fileMessage.success = true;
                        fileMessage.message = 'Ficheiro Carregado com sucesso.';
                        messages.push(fileMessage);
                    }
                    
                } catch(err) {
                    messages.push({
                        success: false,
                        message: 'Problema ao caregar ficheiro.',
                    });
                    fileSuccess = false;
                }

                commit('processingRequest', false);

                return {
                    isUpdate: update,
                    success: queryRes.status.success && fileSuccess,
                    data: { ...data, id},
                    messages: [{
                        success: queryRes.status.success,
                        message: queryRes.status.message
                    }, ...messages],
                };
            } catch(err) {
                commit('processingRequest', false);
                return {
                    success: false,
                    isUpdate: update,
                    messages: [{
                        success: false,
                        message: 'Problema no processamento do pedido.',
                    }]
                };
            }
        },

        async isFestivalProfile({ commit }, payload) {
            try {
                const res = await PALOPTL().post(GQL_API, {
                    query: isFestivalProfile,
                    variables: { ...payload }
                });
                const result = res.data.data.isFestivalProfile
                if (result) {
                    commit('isFestivalProfile', {
                        status: result.requestfestivalprofile.status == 'APPROVED'
                        ? true
                        : false
                    });
                }
            } catch (error) {
                return {
                    success: false,
                    messages: [{
                        success: false,
                        message: 'Erro na verificação de Perfil.',
                    }]
                };
            }
        }
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.requests)) {
                return null;
            }
            const len = state.requests.length;
            return state.requests[len -1].$cursor;
        }
    }
}


function toRequest(edge) {
    const node = edge.node;
    if (!node) return null;
    const URI = `storage/request-profile-festival/proof/${node.id}/`
    return {
        id: node.id,
        requestDate: node.requestDate,
        proof: node.proof ? `${DOMAIN}/${URI}` : null ,
        validationDate: node.validationDate,
        description: node.description,
        status: node.status,
        $cursor: edge.cursor,
        $route: { 
            detail: {path: `${profileDashboard.festival.authorizationRequest.detail}/${node.id}`,},
        }
    };
}