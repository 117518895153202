
const HOME = '/';
const NEWS = '/noticia';
const ABOUT_US = '/quem-somos';
const ABOUT_US_PARTNERS = `${ABOUT_US}/instituicoes-parceiras`;
const ABOUT_US_PROJECTS = `${ABOUT_US}/projeto`;


const FESTIVAL = '/festival';
const PROJECT = '/obra';
const PROJECT_CATEGORY = '/obra/categoria';


const DATABASE = '/base-de-dados';
const AUTHOR = '/autor';
const STORE = '/loja';
const CONTACT = '/contacto';

const TERMO_USO = '/termo-uso';

const LOGIN = '/login';
const SIGN_UP = '/sign-up';
const PROFILE = '/perfil';
const PROFILE_DASHBOARD = '/perfil/dashboard';
const _ACCOUNT = '/conta';

const _controlPanel = '/control-panel'
const CONTROL_PANEL = Object.freeze({
    home: _controlPanel,
    projects: Object.freeze({
        index: `${_controlPanel}/projeto`,
        create: `${_controlPanel}/projeto/novo`,
        update: `${_controlPanel}/projeto/atualizar`,

    }),
    subscriptions: `${_controlPanel}/submissoes`,
    dashboard: {
        subscriptions: `/perfil/dashboard/festival/subscricao`,
    }
});

const _juryPanel = '/painel-juri';
const JURY_PANEL = Object.freeze({
    home: _juryPanel+'/',
    main: Object.freeze({
        index: `${_juryPanel}/principal/:festival/projects`,
        name: 'jury-painel-main'
    }),
    festival: Object.freeze({
        index: `${_juryPanel}/festival/:festival/projects`,
        name: 'jury-painel-festival'
    }),
    auxiliary: Object.freeze({
        index: `${_juryPanel}/auxiliar/:festival/projects`,
        name: 'jury-painel-auxiliary'
    }),
    jury: Object.freeze({
        index: `${_juryPanel}/juri/:festival/projects`,
        name: 'jury-painel-jury'
    }),
});

const ACCOUNT = Object.freeze({
    index: _ACCOUNT,
    confirmation: `${_ACCOUNT}/confirmacao`,
    reset: `${_ACCOUNT}/recuperar`,
})

const profileDashboard = Object.freeze({
    index: PROFILE_DASHBOARD,
    festival: {
        authorizationRequest: {
            index: `${PROFILE_DASHBOARD}/festival/autorizacao`,
            new: `${PROFILE_DASHBOARD}/festival/autorizacao/nova`,
            detail: `${PROFILE_DASHBOARD}/festival/autorizacao`,
        },
        myFestivals: {
            index: `${PROFILE_DASHBOARD}/festival`,
            new: `${PROFILE_DASHBOARD}/festival/nova`,
            detail: `${PROFILE_DASHBOARD}/festival`,
            edit: `${PROFILE_DASHBOARD}/festival/atualizar`,
        },
        mySubscriptions: `${PROFILE_DASHBOARD}/festival/subscricao`,
    }
})

export {
    HOME,
    NEWS,
    ABOUT_US,
    ABOUT_US_PARTNERS,
    ABOUT_US_PROJECTS,
    FESTIVAL,
    PROJECT,
    PROJECT_CATEGORY,
    AUTHOR,
    DATABASE,
    STORE,
    CONTACT,
    LOGIN,
    SIGN_UP,
    PROFILE,
    PROFILE_DASHBOARD,
    ACCOUNT,
    profileDashboard,
    TERMO_USO,
    CONTROL_PANEL,
    JURY_PANEL
}