<template>
    <a v-if="current > -1" @click="goTo" class="page-nav" :class="parentStyles">
            <div :style="styles">
                <font-awesome-icon class="icon" size="2x" icon="play" />
            </div>
    </a>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'left',
        },
        items: {
            type: Array,
            default: () => [],
        }
    },
    data: () => ({ current: 0 }),
    watch: {
        '$route': function (nr) {
            this.current = this.items.findIndex(r => r === nr.path);
        },
    },
    computed: {
        styles() {
            const s = {
                position: 'absolute', 
                left: '25%', 
                top: '50%', 
            };

            if (this.type != 'right') {
                s['transform'] = 'rotate(180deg)';
            }

            return s;
        },
        parentStyles() {
            if (this.type === 'right') {
               return 'right';
            }

            return 'left';
        }
    },
    methods: {
        goTo() {
            const len = this.items.length;
            let npos = this.current;

            if (this.type === 'right') {
                npos = (npos + 1) % len;
            } else {
                npos = npos - 1;
                if (npos < 0) {
                    npos = len - 1;
                }
            }
            
            this.$router.push({ path: this.items[npos]});
        }
    }
}
</script>