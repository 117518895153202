import project from './modules/project';

export default {
    strict: true,
    namespaced: true,
    state: {

    },
    modules: {
        project: project,
    },
    actions: {

    },
    mutations: {

    },
    getters: {

    }
}