<template>
  <v-app v-if="showLandingPage" style="background-color: #171717;">
    <LandingPage  :show="showLandingPage" @close="goHome"/>
  </v-app>
  <v-app v-else>
    <Header :menus="menus"/>
    <v-main>
        <PageNav :items="pageNavLinks"/>
        <PageNav type="right" :items="pageNavLinks"/>
        <v-container class="pt-0" style="height: 100%; position: relative;">
          <router-view></router-view>
        </v-container>
    </v-main>
    <Footer :links="links" />
  </v-app>
</template>

<script>
import LandingPage from './components/LandingPage';
import Header from './components/Header';
import Footer from './components/Footer';
import PageNav from './components/PageNav';

import {
    HOME,
    NEWS,
    ABOUT_US,
    ABOUT_US_PARTNERS,
    ABOUT_US_PROJECTS,
    FESTIVAL,
    //PROJECT_CATEGORY,
    //AUTHOR,
    DATABASE,
    //STORE,
    CONTACT,
    TERMO_USO
} from './routes';


export default {
  name: 'App',

  components: {
    Header,
    Footer,
    PageNav,
    LandingPage,
  },
  data: () => ({
    dialog: true,
    current: null,
    links: [
      {
        section: 'Quem Somos',
        items: [
          {
            title: 'Projeto',
            href: ABOUT_US_PROJECTS,
          },
          {
            title: 'Instituições Parceiras',
            href: ABOUT_US_PARTNERS,
          },
          {
            title: 'Termos de Uso',
            href: TERMO_USO,
          },
        ],
      },
      {
        section: 'Festivais',
        items: [
          {
            title: 'PALOP',
            href: '#palop',
          },
          {
            title: 'Brasil & Portugal',
            href: '#brasil-portugal',
          },
          {
            title: 'Outros',
            href: '#series',
          },
        ],
      },
      /*{
        section: 'Obras',
        items: [
          {
            title: 'Filmes',
            href: '#filmes',
          },
          {
            title: 'Séries',
            href: '#series',
          },
          
        ],
      },*/
      {
        section: 'Filmes',
        href: '#filmes',
        items: [
          {
            title: 'Séries',
            href: '#series',
          },
          
        ],
      },
      {
        section: 'Follow',
        items: [
          {
            title: 'Facebook',
            href: 'https://www.facebook.com/redepaloptlcinema',
            target: '_blank',
          },
          {
            title: 'Instagram',
            href: 'https://www.instagram.com/redecinemapalop_tl/?hl=pt',
            target: '_blank',
          },
        ],
      },
    ],
    menus: [
      {
        title: 'Início',
        route: HOME,
        class: 'd-none d-md-flex d-lg-flex d-xl-flex',
      },
      {
        title: 'Quem Somos',
        route: ABOUT_US,
        class: 'd-none d-md-flex d-lg-flex d-xl-flex',
        children: [
          {
            title: 'Projecto',
            route: ABOUT_US_PROJECTS
          },
          {
            title: 'Parceiros',
            route: ABOUT_US_PARTNERS
          }
        ]
      },
      /*{
        title: 'Festivais',
        route: FESTIVAL,
        class: 'd-none d-md-flex d-lg-flex d-xl-flex',
        
      },
      {
        title: 'Obras',
        route: PROJECT_CATEGORY,
        class: 'd-none d-md-flex d-lg-flex d-xl-flex',
      },
      {
        title: 'Autores',
        route: AUTHOR,
        class: 'd-none d-md-flex d-lg-flex d-xl-flex',
      },*/
      {
        title: 'Notícias',
        route: NEWS,
        class: 'd-none d-md-flex d-lg-flex d-xl-flex',
        
      },
      {
        title: 'Base de dados',
        route: DATABASE,
        class: 'd-none d-md-flex d-lg-flex d-xl-flex',
        children: [
          {
            title: 'Autores',
            route: DATABASE,//AUTHOR,
            //class: 'd-none d-md-flex d-lg-flex d-xl-flex',
          },
          {
            title: 'Festivais',
            route: FESTIVAL,
            //class: 'd-none d-md-flex d-lg-flex d-xl-flex',
            
          },
          /*{
            title: 'Obras',
            route: PROJECT_CATEGORY,
            //class: 'd-none d-md-flex d-lg-flex d-xl-flex',
          },*/
          
        ]
      },
      /*{
        title: 'Loja',
        route: STORE,
        class: 'store-btn',
      },*/
      {
        title: 'Contactos',
        class: 'contact-btn',
        route: CONTACT,
      },
    ],
  }),
  watch: {
      '$route': function (nr) {
          this.current = nr.path;
      },
  },
  computed: {
    pageNavLinks() {
      const l = [];
      this.menus.forEach(m1 => {
        if (m1.route) {
          l.push(m1.route);
          if (m1.children) {
            m1.children.forEach(m2 => {
              if (m2.route) {
                l.push(m2.route);
              }
            })
          }
        }
      });
      return l;
    },
    showLandingPage() {
        return this.dialog && this.homePage;
    },
    homePage() {
        return this.current === '/';
    },
   
  },
  methods: {
     goHome() {
      this.dialog = false;
    }
  }
};
</script>
