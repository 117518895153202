export const list = `query($limit: Int!, $profile: ID! $cursor: String) {
    requests: festivalProfileRequests(first: $limit, profile: $profile, after: $cursor) {
        pageInfo {
            hasNextPage
        }
        edges {
            node { 
                id 
                status
                requestDate
                proof
                validationDate
            }
            cursor
        }  
    }
}`;

export const detail = `query ($id: ID!) {
    request: festivalProfileRequest(request: $id) {
      id 
      requestDate
      validationDate
      proof
      description
      status
      
    }
}`;

export const create = `mutation ($input: RequestFestivalProfileMutationInput!) {
    createRequest: requestFestivalProfile(input: $input) {
      request {
        id
      },
      status {
        success message
      }
    }
}`;

export const isFestivalProfile = `query check($id: ID!){
  isFestivalProfile: author(id: $id) {
    requestfestivalprofile{
      status 
    }
  }
}`;