import VueRouter from 'vue-router';
import {
    HOME,
    NEWS,
    FESTIVAL,
    PROJECT,
    PROJECT_CATEGORY,
    ABOUT_US,
    ABOUT_US_PROJECTS,
    ABOUT_US_PARTNERS,
    CONTACT,
    AUTHOR,
    STORE,
    DATABASE,
    ACCOUNT,
    PROFILE,
    PROFILE_DASHBOARD,
    profileDashboard,
    TERMO_USO,
    CONTROL_PANEL,
    JURY_PANEL,
    //SIGN_UP,
    //LOGIN,
} from './routes';

const Home = () =>
    import ('./pages/Home.vue');
const Contact = () =>
    import ('./pages/Contact.vue');

const AboutUs = () =>
    import ('./pages/about/AboutUs.vue');
const AboutProject = () =>
    import ('./pages/about/Project.vue');
const AboutPartner = () =>
    import ('./pages/about/Partner.vue');

const News = () =>
     import ('./pages/News.vue');
const NewsDetail = () =>
    import ('./pages/NewsDetail.vue');

const Festival = () =>
     import ('./pages/Festival.vue');
const FestivalDetail = () =>
     import ('./pages/FestivalDetail.vue');

// const Project = () =>
//     import ('./pages/Project.vue');
// const ProjectDetail = () =>
//     import ('./pages/ProjectDetail.vue');

// const ProjectCategoryDetails = () =>
//     import ('./pages/ProjectCategoryDetails.vue');

// const Author = () =>
//     import ('./pages/Author.vue');
const AuthorDetail = () =>
    import ('./pages/AuthorDetail.vue');

const AuthorDatabase = () =>
    import ('./pages/AuthorDatabase.vue');

const TermoUso = () =>
    import ('./pages/TermoUso.vue');

// const Login = () =>
//     import ('./pages/Login');
// const SignUp = () =>
//     import ('./pages/SignUp');


const AccountConfirmation = () =>
    import ('./pages/account/Confirmation.vue');

const AccountReset = () =>
    import ('./pages/account/Reset.vue');

const AccountResetConfirmation = () =>
    import ('./pages/account/ResetConfirmation.vue');

const Profile = () =>
    import ('./pages/Profile');

const Subscriptions = () => 
    import('./pages/dashboard/festival/festival/Subscriptions');

const ControlPanel = () =>
    import('./pages/dashboard/control-panel/ControlPanel');

const ControlPanelProjects = () =>
    import('./pages/dashboard/control-panel/Projects');

const ControlPanelProjectCreate = () =>
    import('./pages/dashboard/control-panel/ProjectCreate');

const ControlPanelProjectUpdate = () =>
    import('./pages/dashboard/control-panel/ProjectUpdate');

    const ControlPanelProjectDetail = () =>
    import('./pages/dashboard/control-panel/ProjectDetail');
/*const ControlPanelSubscriptions = () =>
    import('./pages/dashboard/control-panel/Subscriptions');*/

const JuryPanelAuxiliary = () =>
    import('./pages/dashboard/jury-panel/auxiliary/Index');

const JuryPanelJury = () =>
    import('./pages/dashboard/jury-panel/jury/Index');

const JuryPanelMain = () =>
    import('./pages/dashboard/jury-panel/main/Index');

const JuryPanelFestival = () =>
    import('./pages/dashboard/jury-panel/festival/Index');

const JuryPanelHome = () =>
    import('./pages/dashboard/jury-panel/home/Index');

const dashboard = {
    layout: () => import ('./pages/dashboard/Layout'),
    index: () => import ('./pages/dashboard/Index'),
    festival: {
        request: {
            index: () => import('./pages/dashboard/festival/request/List'),
            new: () => import('./pages/dashboard/festival/request/Form'),
            detail: () => import('./pages/dashboard/festival/request/Detail'),
        },
        myFestivals: {
            index: () => import('./pages/dashboard/festival/festival/List'),
            new: () => import('./pages/dashboard/festival/festival/Form'),
            detail: () => import('./pages/dashboard/festival/festival/Detail'),
            edit: () => import('./pages/dashboard/festival/festival/Update'),
        }
    }
}

/*const profile = {
    layout: () => import ('./pages/profile/Layout'),
}*/


const _InDevelopment_ = () =>
    import ('./pages/_InDevelopment_');

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: HOME, component: Home },
        { path: CONTACT, component: Contact },

        { path: TERMO_USO, component: TermoUso },


        { path: ABOUT_US, component: AboutUs },
        { path: ABOUT_US_PROJECTS, component: AboutProject },
        { path: ABOUT_US_PARTNERS, component: AboutPartner },

        { path: AUTHOR, component: _InDevelopment_ },
        { path: `${AUTHOR}/:username`, component: AuthorDetail },

        { path: STORE, component: _InDevelopment_ },
        { path: DATABASE, component: AuthorDatabase },




        { path: NEWS, component: News },
        { path: `${NEWS}/:id`, component: NewsDetail },

        { path: FESTIVAL, component: Festival },
        { path: `${FESTIVAL}/:id`, component: FestivalDetail },

        { path: PROJECT_CATEGORY, component: _InDevelopment_ },
        { path: `${PROJECT}/:id`, component: _InDevelopment_ },
        { path: `${PROJECT_CATEGORY}/:categoryId`, component: _InDevelopment_ },

        { path: CONTROL_PANEL.home, component: ControlPanel },
        { path: CONTROL_PANEL.projects.index, component: ControlPanelProjects },
        { path: CONTROL_PANEL.projects.create, component: ControlPanelProjectCreate },
        { path: CONTROL_PANEL.projects.update, component: ControlPanelProjectUpdate },
        { path: `${CONTROL_PANEL.projects.index}/:id`, component: ControlPanelProjectDetail },
        
        //{ path: CONTROL_PANEL.subscriptions, component: ControlPanelSubscriptions },

        // { path: LOGIN, component: Login, meta: { scope: 'GUEST' } },
        // { path: SIGN_UP, component: SignUp, meta: { scope: 'GUEST' } },
        { path: JURY_PANEL.home, component: JuryPanelHome },
        { path: JURY_PANEL.auxiliary.index, name: JURY_PANEL.auxiliary.name, component: JuryPanelAuxiliary },
        { path: JURY_PANEL.jury.index, name: JURY_PANEL.jury.name, component: JuryPanelJury },
        { path: JURY_PANEL.main.index, name: JURY_PANEL.main.name, component: JuryPanelMain },
        { path: JURY_PANEL.festival.index, name: JURY_PANEL.festival.name, component: JuryPanelFestival },


        { path: PROFILE, component: Profile, meta: { requiresAuth: true } },
        /*
        { 
            path: PROFILE, 
            component: profile.layout, 
            meta: { requiresAuth: true },
            children: [
                { path: '/', component: Profile, meta: { requiresAuth: true } },
            ]
        },
        */
        { 
            path: PROFILE_DASHBOARD, 
            component: dashboard.layout, 
            meta: { requiresAuth: true },
            children: [
                { path: '/', component: dashboard.index,  },
                { 
                    path: profileDashboard.festival.authorizationRequest.index, 
                    component: dashboard.festival.request.index
                },
                { 
                    path: profileDashboard.festival.authorizationRequest.new, 
                    component: dashboard.festival.request.new
                },
                { 
                    path: `${profileDashboard.festival.authorizationRequest.detail}/:id`, 
                    component: dashboard.festival.request.detail
                },


                { 
                    path: profileDashboard.festival.mySubscriptions, 
                    component: Subscriptions
                },

                //must come last
                
                { 
                    path: profileDashboard.festival.myFestivals.index, 
                    component: dashboard.festival.myFestivals.index
                },
                { 
                    path: profileDashboard.festival.myFestivals.new, 
                    component: dashboard.festival.myFestivals.new
                },
                { 
                    path: `${profileDashboard.festival.myFestivals.detail}/:id`, 
                    component: dashboard.festival.myFestivals.detail
                },
                { 
                    path: `${profileDashboard.festival.myFestivals.edit}/:id`, 
                    component: dashboard.festival.myFestivals.edit
                },

            ]
        },


        { 
            path: `${ACCOUNT.confirmation}/:uid/:token`, 
            component: AccountConfirmation, meta: { scope: 'GUEST' }
        },

        { 
            path: ACCOUNT.reset, 
            component: AccountReset, meta: { scope: 'GUEST' }
        },

        { 
            path: `${ACCOUNT.reset}/:uid/:token`, 
            component: AccountResetConfirmation, meta: { scope: 'GUEST' }
        },


    ],
});

/*router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      
      const logged = true;
      if (logged) {
        next({
          path: LOGIN,
          //query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
});*/

export default router;
