import {PALOPTL, GQL_API, FILE_URL } from '../../../utils/api';
//import { JURY_PANEL } from '../../../routes';

import { dashboard } from '../../../gql';

import _ from 'lodash';


// Festival Profile Request
export default {
    strict: true,
    namespaced: true,
    state: {
        items: [],
        loading: false,
        item:null,
        processing: false,
        hasMore: false,
        subscriptions: []
    },
    mutations: {
        items(state, payload) {
            state.items = payload || [];
        },
        item(state, payload) {
            state.item = payload;
        },
        appendItems(state, payload) {
            state.items = _.concat(state.items, payload || []);
        },
        loading(state, payload) {
            state.loading = payload;
        },
        processing(state, payload) {
            state.processing = payload;
        },
        hasMore(state, payload) {
            state.hasMore = payload;
        },
        subscriptions(state, payload) {
            state.subscriptions = payload;
        }
    },

    actions: {
        /**
         * @description list all projects
         * @param {*} param0 
         */
        async detail({ commit }/*, payload*/) {
            commit('loading', true);
            commit('item', null);

            
            commit('loading', false);
            // commit('item', one);
        },

        async load({ commit }, payload) {
            commit('loading', true);

            const variables = {
                festival: payload.festival,
                status: "PENDDING" //TODO change it to approved
            };

            const res = await PALOPTL().post(GQL_API, {
                query: dashboard.juryPanel.main.projects,
                variables: variables
            });

            const queryRes = res.data.data.subscriptions;
            const subscriptions = (queryRes.edges || []).map(toItem);
            
            commit('loading', false);
            commit('hasMore', queryRes.pageInfo.hasNextPage);
            return subscriptions;
        },
        
        async list ({ commit, dispatch }, payload)  {
            try {
                const items = await dispatch('load', payload);
                commit('items', items);
            } catch (err) {
                commit('loading', false);
            }
        },
        async loadMore({ commit, dispatch, getters }, payload) {
            try {
                const items = await dispatch('load', { ...payload, cursor: getters.lastCursor});
                commit('appendItems', items);
            } catch(err) {
                commit('loading', false);
            }
        },

        async submitReview({ commit, state }, payload) {
            const update = false; 
            commit('processing', true);

            try {
                const input = { ...payload };

                let query = null;
                if (update)
                    query = null;
                else
                    query = dashboard.juryPanel.main.submitReview;
                    
                const res = query ? await PALOPTL().post(GQL_API, {
                    query: query,
                    variables: { input }
                }) : {
                    request: { id: payload.id },
                    status: { message: 'Não foi feito request', success: false }
                };

                const queryRes = update ?
                    res :
                    res.data.data.submitPrincipalReview;
                

                if (!queryRes.review) {
                    commit('processing', false);
                    return { 
                        isUpdate: update, 
                        success: false, 
                        messages: [{
                            success: false,
                            message:  queryRes.status.message,
                        }]
                    };
                }

                const id = queryRes.review.id;
                
                const index = _.findIndex(state.items, ['subscription', payload.subscription]);
                Object.assign(state.items[index].review, queryRes.review);

                commit('processing', false);

                return {
                    isUpdate: update,
                    success: queryRes.status.success ,
                    data: { ...payload, id },
                    messages: [{
                        success: queryRes.status.success,
                        message: queryRes.status.message
                    }],
                };
            } catch(err) {
                commit('processing', false);
                return {
                    success: false,
                    isUpdate: update,
                    messages: [{
                        success: false,
                        message: 'Problema no processamento do pedido.',
                    }]
                };
            }
        },
    },
    getters: {
        lastCursor(state) {
            if (_.isEmpty(state.requests)) {
                return null;
            }
            const len = state.requests.length;
            return state.requests[len -1].$cursor;
        }
    }
}

function toItem(node) {
    if (!node) return null;
    
    return {
        "$cursor": node.node.cursor,
        subscription: node.node.id,
        status: node.node.status,
        review: { ...node.node.review },
        project: {
            ...node.node.project,
            duration: 'N/D',
            src: FILE_URL + '/' + node.node.project.cover,
            "$route": {
                "view": `/control-panel/projeto/${ node.node.project.id }`,
            },
            credits: (node.node.project.credits.edges || []).map(node => node.node.name)
        }
    }
}
